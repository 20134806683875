label.req {
  font-weight: bolder;

  &:before {
    content: '*';
    color: var(--bs-danger);
    margin-right: 3px;
  }
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.form-group.error {

}
.form-group label {
  //font-family: var(--font-medium);
  color: var(--dark-color);
  margin-bottom: 8px;
  font-size: 14px;
}

.form-control-wrap {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: var(--bg-secondary);
}

.input-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 20px;
  z-index: 1;
  pointer-events: none;
}

.form-group.clearable .form-control {
  padding-right: 56px;
}

.input-clear {
  z-index: 1;
  position: absolute;
  right: 16px;
}

.form-control {
  width: 100%;
  //font-family: var(--font-medium);
  font-family: var(--font-bold);
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 16px;
  min-height: 64px;
  z-index: 1;
  position: relative;
  padding-left: 24px;
  &:focus + .form-control-bg, &:active + .form-control-bg {
    opacity: 1;
  }

}

.form-control::placeholder {
  font-size: 16px;
  font-family: var(--font-bold);
  color: rgba(255, 255, 255, 0.2);

}

.form-control:focus {
  color: #fff;
  outline: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.form-control-bg {
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  background: var(--gradient);
  border-radius: 17px;
  transition: var(--transition);
  opacity: 0;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    left: -3px;
    right: -3px;
    top: -3px;
    bottom: -3px;
    background: var(--gradient);
    border-radius: 19px;
    transition: var(--transition);
    opacity: 0.32;
  }

  &:after {
    content: '';
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    background: var(--bg-secondary);
    border-radius: 16px;
  }
}

.form-control-password {
  padding-right: 54px;
}

select.form-control {
  position: relative;
  padding-right: 48px;
  &:has(option[value=""]:not(:checked)) {
    color: #fff !important;
  }
}

.form-control-wrap:has(select):after {
  content: '';
  transition: var(--transition);
  z-index: 112;
  position: absolute;
  pointer-events: none;
  right: 20px;
  width: 24px;
  height: 24px;
  background: url("../../../../assets/icons/chevron_down.svg") no-repeat right center/ 100%;
}

.form-control-wrap:has(select:active):after {
  transform: rotate(180deg);
}

.form-control-icon {
  padding-left: 54px;
}


.form-group-error {
  position: absolute;
  right: 0;
  color: var(--re);
  font-size: clamp(14px, 2vw, 16px);
}

.form-group-file {
  position: relative;
  font-size: clamp(14px, 2vw, 16px);
  display: flex;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  min-height: 56px;
  padding: 12px;
  padding-bottom: 4px;
}

.form-file-label {
  color: var(--muted-color);
  font-family: var(--font-bold);
  cursor: pointer;
  position: relative;
  height: 48px;
  border-radius: 32px;
  background: linear-gradient(#36353A, #36353A) padding-box, var(--gradient) border-box;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding: 0 20px 0 12px;
  transition: var(--transition);
}

.form-file-label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-right: 4px;
  flex: 1;
}

.form-file-label:hover {
  color: var(--text-color)
}

.form-file-label path {
  transition: var(--transition);
}

.form-file-label:hover path {
  stroke-opacity: 1;
}

.form-group-file input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.form-file-list-item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: clamp(14px, 2vw, 16px);
  line-height: clamp(16px, 2vw, 20px);
  height: 32px;
  color: var(--muted-color);
  padding: 0 8px 0 12px;
  max-width: 202px;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: default;
}

.form-file-list-item:hover {
  color: var(--text-color)
}

.form-file-list-item:hover path {
  fill-opacity: 1;
}

.form-group-file-placeholder {
  font-family: var(--font-refular);
  color: rgba(255, 255, 255, 0.4);
}

.form-group-file .input-icon + .form-group-file-placeholder {
  padding-left: 46px;
}

.form-group-file .input-icon {
  left: 3px;
  top: 3px;
}

textarea.form-control {
  resize: none;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 124px;
}

.input-pass-eye {
  cursor: pointer;
  position: absolute;
  right: 16px;
  width: 32px;
  height: 32px;
  z-index: 1;
}

.form-password-forgot {
  margin-right: 16px;
  cursor: pointer;
  font-size: 14px;
  color: var(--muted-color);
  transition: var(--transition);
}

.form-password-forgot:hover {
  text-decoration: underline;
}

.input-file__progress {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.form-file-icon {
  width: 104px;
  height: 104px;
  min-width: 104px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  margin-left: -8px;
  margin-top: -8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.form-group.error .form-control {
  border-color: var(--danger);
}

.form-control option {
  background: var(--bg-main);
  font-family: var(--font-refular);
  margin-left: -32px;
  color: #fff;


  &.active {
    background-color: #202020;
  }

  &:hover {
    color: var(--bg-main) !important;
  }

  &:checked {
    background-color: #202020;
  }

  &:disabled {
    opacity: 0;
    font-size: 0;
    //background-color: red;
  }
}

select.form-control[name= ''] {
  font-family: var(--font-refular);
  font-size: clamp(14px, 2vw, 16px);
  color: var(--muted-color);
}

.form-control[type= 'date'] {
  font-size: var(--font-refular);
  align-content: center;
  color: var(--muted-color);
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.form-control[type= 'time'] {
  align-content: center;
}

.form-control[type= 'date']:valid {
  color: var(--text-color);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  content: '';
  width: 20px;
  top: 0;
  bottom: 0;
  pointer-events: none;
  //background: url("../../assets/images/icons/calendar.svg") no-repeat center;
  /* This is the calendar icon in FontAwesome */
  position: absolute;
  right: 12px;
  color: #999;
}

input[type="time"]:before {
  content: '';
  width: 20px;
  top: 0;
  bottom: 0;
  pointer-events: none;
  //background: url("../../assets/images/icons/time.svg") no-repeat center;
  /* This is the calendar icon in FontAwesome */
  position: absolute;
  right: 12px;
  color: #999;
}

.input-group {
  display: flex;
  align-items: flex-end;
}

.input-group .form-control {
  border-right-width: 0;
  border-radius: 0;
}


@media only screen and (max-width: 992px) {
  .form-group-file {
    /*padding: 8px;*/
    padding: 8px 8px 4px;
  }
  .form-file-label-wrap {
    padding-right: 0px;
  }
  .form-control {
    min-height: 48px;
  }

  .input-icon {
    width: 40px;
    height: 40px;
  }

  .input-icon svg {
    width: 24px;
  }

  .form-control-icon {
    padding-left: 52px;
  }

  textarea.form-control {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .form-control::placeholder, .form-group-file-placeholder, .form-group-file {
    font-size: 14px;
  }

  .form-file-label {
    font-size: 14px;
    height: 32px;
    border-radius: 32px;
  }

  .form-file-label svg {
    width: 18px;
  }

  .form-password-forgot {
    font-size: 14px;
  }
  .form-group-error {
    font-size: 14px;
  }
}

.form-check {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;

}

.form-check-input {
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-height: 24px;
  margin-top: 0;
  opacity: 0;

  &:focus {
    box-shadow: none;
  }
}

.form-check .form-check-input {

  &:hover + .form-check-custom {
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.15);
  }

  &:active + .form-check-custom {
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.15);
  }

  &:checked + .form-check-custom {
    background: var(--gradient);
    box-shadow: none;

    svg {
      display: block;
    }
  }
}

.form-check-label {
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--dark-color);
  padding-left: 12px;
}

.form-check-custom {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 6px;
  border: none;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  background-color: var(--bg-main);
  transition: var(--transition);
  color: var(--text-color);

  svg {
    display: none;
  }
}


.search-input {
  position: relative;
  display: flex;
  align-items: center;

  input.form-control {
    padding-left: 56px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.64);
      font-family: var(--font-regular);
    }
  }
}



.form-group.lg, .form-group.sm {
  .form-control-wrap {
    border-radius: 24px;
  }

  .form-control {
    border-radius: 24px;
  }

  .form-control-bg {
    border-radius: 25px;

    &:before {
      border-radius: 27px;
    }

    &:after {
      border-radius: 24px;
    }
  }
}

.form-group.lg .form-control {
  min-height: 72px;
  padding-left: 32px;

  &::placeholder {
    font-size: 16px;
  }
}

.form-group.sm {
  .form-control {
    min-height: 40px;
    font-size: 14px;
    padding-left: 40px;
    &::placeholder {
      font-size: 14px;
    }
  }
  select.form-control {
    padding-right: 30px;
  }
  .input-icon {
    z-index: 2;
    width: 20px;
    height: 20px;
    min-width: 20px;
    left: 12px;
  }
  .form-control-wrap:has(select):after {
    right: 12px;
    background: url("../../../../assets/icons/chevron_b_16.svg") no-repeat  center;
  }
}



.form-radio {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form-radio-input:checked {
  & + .form-radio-custom + .form-radio-label {
    color: var(--tint-color);
  }

  & + .form-radio-custom {
    border: none;
    background: var(--gradient);

    &:after {
      opacity: 1;
    }
  }
}

.form-radio-input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  width: 24px;
  min-width: 24px;
  height: 24px;

  &:hover {
    & + .form-radio-custom {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
}


.form-radio-label {
  font-family: var(--font-bold);
  transition: var(--transition);
  padding-left: 8px;
}

.form-select-placeholder {
  position: absolute;
  color: var(--text-muted);
}


.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback), {
  .form-control-wrap {
    border-radius: 0 24px 24px 0;
  }
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  .form-control-wrap {
    border-radius: 24px 0 0 24px;
  }
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

input[type='date']:in-range::-webkit-datetime-edit-year-field, input[type='date']:in-range::-webkit-datetime-edit-month-field, input[type='date']:in-range::-webkit-datetime-edit-day-field, input[type='date']:in-range::-webkit-datetime-edit-text, {
  color: transparent;
}

input[type='time']:in-range::-webkit-datetime-edit-year-field, input[type='time']:in-range::-webkit-datetime-edit-month-field, input[type='time']:in-range::-webkit-datetime-edit-day-field, input[type='time']:in-range::-webkit-datetime-edit-hour-field, input[type='time']:in-range::-webkit-datetime-edit-fields-wrapper, input[type='time']:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;

}

input[type="date"][value=""]:not(:focus), input[type="time"][value=""]:not(:focus), {
  color: transparent;
}

.form-control-placeholder {
  pointer-events: none;
  position: absolute;
  left: 24px;
  color: var(--muted-color);
  z-index: 1;
}

.input-file-wrap {
  align-self: start;
}