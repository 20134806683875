.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
  font-family: var(--font-regular);
}
.tags-list-item {
  height: 40px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 12px;
  cursor: pointer;
  transition: var(--transition);
  min-width: 60px;
  justify-content: center;
  position: relative;
  &:hover {
    background: rgba(255, 255, 255, 0.16);
  }
  &.active {
    background-color: #fff;
    .tags-list-item-text {
      background: -webkit-linear-gradient(left, var(--tint-color), var(--secondary-color));
      background: -o-linear-gradient(right, var(--tint-color), var(--secondary-color));
      background: -moz-linear-gradient(right, var(--tint-color), var(--secondary-color));
      background: linear-gradient(to right, var(--tint-color), var(--secondary-color));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    } 
  }
}