@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .settings-page {
    padding-bottom: 0px;
  }

}

@media only screen and (max-width: 767px) {

  .settings-card {
    padding: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .remove-acc-btn {
    width: 100%;
  }
  .settings-card {
    align-items: center;

    &:before {
      left: auto;
    }

    .avatar {
      align-self: center;
    }
  }
  .settings-items {
    padding-left: 0;
    margin: 0;
    gap: 8px;
    margin-top: 16px;
  }
  .settings-item-container {
    width: 100%;
    padding: 0;
  }
  .settings-sub-head {
    gap: 16px;

    > .flex-1 {
      align-items: flex-start !important;
      flex-direction: column;
      width: 100%;
      gap: 4px;
    }

    .settings-sub {
      margin-left: 0;
    }
  }
  .settings-avatar{
    align-self: center;
  }
}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
  .settings-avatar:hover {
    &:after {
      opacity: 1;
    }

    .avatar {
      &:after {
        opacity: 1;
      }

      &:before {
        opacity: 0.4;
      }
    }


  }
}