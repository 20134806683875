.settings-page {
  max-width: 748px;
  padding-bottom: 24px;

  .border-gradient-secondary {
    height: 40px;
    margin-left: 8px;

    &:before, &:after {
      border-radius: 21px;
    }
  }
}

.settings-items {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin: -8px;
  padding-left: 24px;
  align-items: flex-start;
}

.settings-item-container {
  position: relative;
  width: 50%;
  padding: 8px;
  display: flex;
  align-items: center;

  &:hover {
    .btn-edit {
      opacity: 1;
    }

    &:before {
      opacity: 1;
    }

    &:after {
      opacity: 0.32;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 7px;
    right: 7px;
    top: 7px;
    bottom: 7px;
    background: var(--gradient);
    border-radius: 17px;
    transition: var(--transition);
    opacity: 0;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
    background: var(--gradient);
    border-radius: 19px;
    transition: var(--transition);
    opacity: 0;
  }

}

.settings-item {
  position: relative;
  z-index: 1;
  padding: 12px 16px;
  border-radius: 16px;
  background-color: var(--bg-main);
  flex: 1;
  display: flex;
  height: 72px;

  .btn-edit {
    top: auto;
    right: auto;
    position: relative;
    align-self: center;
  }
}

.settings-card {
  border-radius: 24px;
  padding: 24px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 216px;
    height: 140px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    filter: blur(60px);
    pointer-events: none;
    top: -20px;
    left: -40px;

  }
}

.settings-notifications {
  display: flex;
  align-items: flex-start;
  background: rgba(18, 18, 18, 0.4);
  margin-top: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  padding: 24px;
}

.settings-plan {

  border-radius: 24px;
  display: flex;
}

.settings-plan-item {
  background: rgba(18, 18, 18, 0.4);
  flex: 1;
  padding: 24px;

  &:first-child {
    border-radius: 24px 0 0 24px;
    margin-right: 1px;
  }

  &:last-child {
    border-radius: 0 24px 24px 0;
    margin-right: 1px;
  }
}

.settings-sub-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 24px;
  position: relative;

  &.subscribed:before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--gradient);
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    opacity: 0.24;
  }
}

.settings-sub-content {
  width: 100%;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
}

.settings-sub-info {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  > div:first-child {
    padding-right: 16px;
    margin-right: 16px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.settings-sub {
  background: var(--gradient);
  margin-left: 8px;
  border-radius: 21px;
  padding: 0 12px;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .react-icon {
    margin-left: -4px;
    margin-right: 8px;
  }

  .react-icon svg {
    width: 16px;
    height: 16px;
  }
}

.settings-sub-head {
  gap: 4px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
}
.settings-avatar {
  position: relative;
  display: block;
  border-radius: 50%;
  height: 102px;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: url("../../../../assets/icons/edit.svg") no-repeat center/24px, rgba(18, 18, 18, 0.16);
    border-radius: 50%;
    transition: var(--transition);
    opacity: 0;
    z-index: 2;
    pointer-events: none;

  }
}
.settings-avatar .avatar {
  position: relative;
  background-color: #fff;
  &:after {
    content: '';
    position: absolute;
    inset: -1px;
    background: var(--gradient);
    z-index: -1;
    border-radius: 50%;
    transition: var(--transition);
    opacity: 0;
    pointer-events: none;
  }
  &:before {
    content: '';
    position: absolute;
    inset: -3px;
    background: var(--gradient);
    z-index: -1;
    border-radius: 50%;
    transition: var(--transition);
    opacity: 0;
    pointer-events: none;
  }

}