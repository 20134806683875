@media only screen and (max-width: 1199px) {
  .gallery-nav .col-3 {
    display: none;
  }
  .gallery-nav .col-9 {
    width: 100%;
  }
  .gallery-nav-list {
    position: relative;
  }
  .gallery-nav-list-container {
    height: 56px;

    .horizontal-scroll {
      .avatar {
        width: 48px;
        height: 48px;
        min-width: 48px;
      }

      .btn {
        font-size: 14px;
        min-height: auto;
      }

      .btn-content {
        height: 56px;
        min-height: auto;
        padding-top: 0;
        padding-bottom: 0;

      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .gallery-list {
    margin-bottom: 16px;
  }
  .gallery-item-model {
    left: 8px;
    bottom: 8px;
    right: 8px;
  }

  .gallery-nav {
    margin-bottom: 16px;
  }
  .gallery-models-all .btn {
    &.active .btn-content {
      padding-right: 16px !important;
    }

    .btn-content {
      padding-right: 32px !important;
      padding-left: 32px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .generate-image-filter-list .model-card-category {
    left: 4px !important;
    bottom: 4px !important;
    right: 4px !important;
  }
}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}