
.timer {
  display: flex;
  align-items: center;
}
.timer.sm {
  .timer-item {
    height: 24px;
    width: 24px;
    font-size: 12px;
    border-radius: 8px;
  }
}

.timer-item {
  height: 40px;
  width: 40px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: var(--font-bold);

  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 50%;
    top: -1px;
    background: rgba(255, 255, 255, 0.08);
  }

  &:after {
    top: auto;
    bottom: -1px;
  }

  &.disabled span {
    opacity: 0.2;
  }
}

.timer-separator {
  width: 8px;
  font-size: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.16);
  font-family: var(--font-bold);
}
