.react-responsive-modal-modal {
  //padding: 16px;
  padding: 0;
  background: none;
  box-shadow: none;
  margin: 0;
  max-width: 100%;
}


.react-responsive-modal-container {
  background: rgba(18, 18, 18, 0.24);
  backdrop-filter: blur(8px);
}


.modal-container {
  background-color: var(--bg-secondary);
  position: relative;
  padding: 24px;
  border-radius: 32px;
  align-self: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 400px;
  max-width: 100%;

  &.big {
    width: 1024px;
  }

  &.medium {
    width: 700px;
  }
}

.modal-close {
  display: inline-flex;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 2;
  cursor: pointer;
}

.modal-title {
  margin-bottom: 24px;
  font-family: var(--font-bold);
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;

  h4 {
    font-family: var(--font-bold);
    font-size: 16px;
    line-height: 24px;
  }
}

.modal-footer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 40px;

  &.sm {
    gap: 16px;
  }

  > * {
    flex: 1;
  }
}

.modal-gallery .slick-slide {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal-gallery .slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.modal-gallery .slick-slider {
}

.modal-gallery .slick-list {
  border-radius: 24px;
}

.gallery-modal-image {
  //width: auto!important;
  border-radius: 24px;
  max-height: 90vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    border-radius: 24px;
    object-fit: contain;
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 90vh !important;
    margin: 0 auto;
  }
}

.modal-gallery {
  padding: 0!important;
  background-color: transparent;
  border: none;
  max-width: 800px;
  width: 500px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  //width: auto;
  .slick-prev {
    left: 16px;
  }

  .slick-next {
    right: 16px;
  }

  .btn-expand {
    left: 16px;
    top: 16px;
  }
}

.modal-with_img {
  padding: 0;
  display: flex;
  border: none;
  overflow: hidden;
}

.modal-with_img-image {
  position: relative;
  z-index: 2;
  width: 50%;
  min-width: 50%;

  img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.modal-with_img-content {
  width: 50%;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  padding: 60px 56px;
  border: 1px solid var(--border-color);
  border-radius: 0 32px 32px 0;
  border-left: 0;
  background-color: var(--bg-secondary);
  position: relative;
  justify-content: center;
}

.modal-with_img-content.with-bg {
  &:before {
    content: '';
    position: absolute;
    width: 650px;
    height: 346px;
    bottom: -210px;
    right: -100px;
    background: url("../images/no_money_bg-2.png") no-repeat center/ 100%;
    opacity: 0.66;
  }

  &:after {
    content: '';
    position: absolute;
    width: 650px;
    height: 346px;
    top: -200px;
    left: -140px;
    background: url("../images/no_money_bg-1.png") no-repeat center/ 100%;
    opacity: 0.66;
  }
}

.modal-with_img-content-bg {
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    background: var(--tint-color);
    filter: blur(50px);
    opacity: 0.32;
    width: 100%;
    height: 50%;
    border-radius: 200px;
  }

  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    background: var(--bg-secondary);
    top: 100px;
    bottom: 100px;
    left: -142px;
    right: -142px;
    filter: blur(32px);
    opacity: 1;
  }
}

.modal-with_img-content-bg-2 {
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    background: var(--gradient);
    filter: blur(50px);
    opacity: 0.32;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 200px;
  }
}


.modal-gender-item {
  padding: 24px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-container {
  .model-profile-slide img {
    width: auto;
  }

  .chats-side-list-container {
    display: block;
  }

  .chats-side-list {
    position: relative;
    overflow: visible;
  }

  .model-profile-info {
    padding: 0;
    padding-top: 16px;
  }
}

.push-notif-modal-logo {
  width: 56px;
  height: 56px;
  margin: 0 auto 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background: var(--gradient);
    filter: blur(25px);
    border-radius: 64px;

  }

  img {
    width: 100%;
    position: relative;
  }
}

.generate-image-modal_models {
  height: 90dvh;
  display: flex;
  flex-direction: column;

  .model-card {
    aspect-ratio: 1/1.42;

    .name-age {
      font-size: 16px;
      line-height: 24px;
    }

    .text-truncate-2 {
    }
  }

}

.generate-image-modal_models-tabs {
  gap: 8px;
  margin-top: 16px;
  height: 48px;

  .tabs {
    width: calc(33% - 4px);
  }

  .horizontal-scroll {
    position: relative;
    padding: 0;
    width: 100%;
    justify-content: space-between;
  }
}

.generate-image-modal_models-list-container {
  position: relative;
  flex: 1;
  margin-bottom: -28px;
  margin-top: 8px;
}

.generate-image-modal_models-list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 8px;
  padding-bottom: 32px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  .active-element-container {
    flex: initial;
  }

  .empty-list {
    padding-bottom: 0;
    margin-top: 24px;
    height: 120px;
  }
}


.generate-image-prompt-modal {
  .form-control-wrap {
    background-color: transparent;
  }

  select {
    background-color: transparent;
  }
}

.share-chat-modal {
  width: 920px;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 !important;
  overflow: hidden;
  background: url("../../../pages/ChatPage/assets/images/bg.png") repeat center center/115px, var(--bg-main);

  .chat-board::-webkit-scrollbar-track {
    margin-top: 24px;
  }
}

.share-chat-modal-board-head-container {
  display: flex;
  position: absolute;
  top: 16px;
  left: 20px;
  right: 12px;
  z-index: 2;
}

.share-chat-modal-board-head {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  background-color: var(--bg-secondary);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding: 8px 12px 8px 8px;

}

.share-chat-modal-close {
  height: 64px;
  width: 64px;
  min-width: 64px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: url("../../../assets/icons/close.svg") no-repeat center, var(--bg-secondary);
  cursor: pointer;
  transition: var(--transition);
  margin-left: 16px;

}

.share-chat-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  background-color: var(--bg-main);
  border-top: 1px solid var(--border-color);
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    background-color: #1E1E1E;
  }

}

.share-chat-modal-chat {
  flex: 1;
  position: relative;
}

.share-chat-modal-confirm {
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: var(--bg-main);
  border-top: 1px solid var(--border-color);
  padding: 24px;
  padding-bottom: 34px;
  transform: translateY(100%);

  &.visible {
    transform: translateY(10px);
  }

  &.visible-trigger {
    transform: translateY(0);
  }

  p {
    text-align: center;
    margin-top: 16px;
    max-width: 480px;
    margin-bottom: 24px;
  }
}

.share-chat-modal-confirm-close {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 24px;
  right: 24px;
  background: url("../../../assets/icons/close.svg") no-repeat center;
  cursor: pointer;
}

.share-chat-modal-confirm-footer {
  display: flex;
  gap: 16px;
  width: 400px;
  max-width: 100%;

  .btn {
    flex: 1;
    white-space: nowrap;
  }
}
.sub-manage-modal-info {
  background: #1E1E1E;
  margin-top: 24px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;

}
.sub-manage-about {
  display: flex;
  padding: 12px 16px;
  border-top: 1px solid #262626;
  gap: 16px;
  .flex-1:nth-child(2) {
    padding: 0 16px;
    border-left: 1px solid #262626;
    border-right: 1px solid #262626;
  }
  .text-bold-14 {
    color: rgba(255, 255, 255, 0.8);

  }
}

.modal-loyalty-system {
  width: auto;
  border: none;
  .loyalty-system-status-item-name {
    width: 100px;
  }
  .loyalty-system-status {
    padding: 8px;
    left: 0;
    margin-top: 0!important;
  }
  .loyalty-system-header {
    display: none;
  }
  .loyalty-system-about {
    margin-top: 8px;
  }
  .loyalty-system {
    padding: 0;
  }
  &:has(.loyalty-system-container.cold) {
    background-color: #1C233D;
  }
  &:has(.loyalty-system-container.friendly) {
    background-color: #4B4222;
  }
  &:has(.loyalty-system-container.horny) {
    background-color: #3D231E;
  }
  &:has(.loyalty-system-container.loving) {
    background-color: #381A1A;
  }
}