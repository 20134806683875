@media only screen and (max-width: 1199px) {
  .create-ai-page {
    .page-title {
      display: none;
    }
  }
  .create-ai-summary-item .model-card-category {
    left: 4px;
    right: 4px;
    bottom: 4px;
  }
}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
  .create-ai-inner-card {
    padding: 16px;
    margin-top: 16px;
  }
  .create-ai-subtitle {
    margin-bottom: 16px;
    font-size: 14px;

  }
  .create-ai-step-footer {
    margin: 32px -8px 0;
    .btn {
      margin: 0 8px;
    }
  }
  .create-ai-list {
    margin: -4px;

  }
  .create-ai-border-item {
    font-size: 14px;
    padding: 16px 12px;
    line-height: 20px;
  }
  .create-ai-list .active-element-container {
    width: 25%;
    padding: 4px;
  }
  .create-ai-page {
    padding: 0 !important;
    margin: 0;
    .steps {
      display: none;
    }
  }
  .create-ai-card {
    width: 100%;
    padding: 24px 16px 16px;
  }
  .create-ai-title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 16px;
    padding: 0 8px;
    &:has(h6) {
      margin-bottom: 24px;
    }
  }
  .create-ai-list + .create-ai-title {
    margin-top: 24px;
  }
  .create-ai-title-step {
    display: flex;
  }
  .create-ai-eye-color {
    padding: 0;
    margin-top: 16px;
  }
  .create-ai-personality-item-text {
    margin-top: 12px;
  }
  .create-ai-personality-item-text {
    font-size: 12px;
    line-height: 18px;
  }
  .create-ai-border-item {
    padding: 16px;
  }
  .create-ai-summary-item {
    width: 33%;
    padding: 6px 4px;
    .model-card-category {
      left: 4px;
      right: 4px;
      bottom: 4px;
    }
  }
  .create-ai-list .model-card-category {
    left: 4px;
    bottom: 4px;
    right: 4px;
    //font-size: 14px;
  }
  .create-ai-summary-item .active-element-content.bordered {
    justify-content: center;
    padding-bottom: 68px;
  }
  .create-ai-style {
    margin: -8px;
    .active-element-container {
      padding: 8px;
    }
    .model-card-category {
      left: 7px;
      top: 7px;
      padding: 0 16px;
    }
  }
  .create-ai-list.step-8 .active-element{
    aspect-ratio: auto;
  }
}

@media only screen and (max-width: 575px) {
  .create-ai-list .active-element-container {
    width: 33%;
  }
  .create-ai-personality .active-element-container {
    width: 50%;
  }
.create-ai-step-summary {
  flex-direction: column-reverse;
  margin-top: 24px;
  gap: 8px;
  padding-left: 8px;
  padding-right: 8px;
  .btn {
    margin: 0;
    width: 100% !important;
  }
}

}

@media only screen and (max-width: 468px) {
  .create-ai-list .active-element-container {
    width: 50%;
  }
}
/* touchscreens */
@media (hover: none) {
  .create-ai-summary-item .active-element-container.button .btn-edit {
    display: block;
  }
 .chat-control:has(.form-control-wrap input:focus) {
   padding-bottom: 40px;
 }
}

/* mouse */
@media (hover: hover) {
  /* ... */
}