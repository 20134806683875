.active-element-container {
  display: flex;
}

.active-element {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 32px;

  &:hover, &.active {
    border-color: transparent;

    &:before, &:after {
      transition: var(--transition);
      opacity: 1;
    }

    .active-element-content {
      &.bordered {
        background: var(--bg-main);
      }

      &:after {
        display: none;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--gradient);
    border-radius: 40px;
    filter: blur(30px);
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border-radius: 34px;
    background: var(--gradient);
    opacity: 0;

    pointer-events: none;
  }

  .form-radio-custom {
    pointer-events: auto;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
  }

}

.active-element.active {
  .model-card-category {
    background-color: #fff;
    backdrop-filter: none;

    span {
      background: -webkit-linear-gradient(left, var(--tint-color), var(--secondary-color));
      background: -o-linear-gradient(right, var(--tint-color), var(--secondary-color));
      background: -moz-linear-gradient(right, var(--tint-color), var(--secondary-color));
      background: linear-gradient(to right, var(--tint-color), var(--secondary-color));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

}

.active-element-content {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 1;
  flex: 1;
  flex-direction: column;
  display: flex;
  border-radius: 32px;
  //background: var(--bg-secondary);
  //transition: var(--transition);
  &.bordered {
    background: var(--bg-secondary);
    &:after {
      //border: 1px solid red;
      pointer-events: none;
      content: '';
      border-radius: 32px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: inset 0 0 0 1px var(--border-color);
    }
  }


}

.active-element-container.small {
  .active-element {
    border-radius: 24px;
  }

  .active-element:after {
    border-radius: 26px;
  }

  .active-element-content {
    border-radius: 24px;

    &:after {
      border-radius: 24px;
    }
  }

  .model-card {
    border-radius: 24px;

    img {
      border-radius: 24px;
    }
  }
}

.active-element-container.bg-blur {
  .active-element-content {
    background-color: var(--bg-secondary);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    &:before {
      opacity: 1;
    }
  }
  .active-element-content:before {
    content: '';
    position: absolute;
    width: 55px;
    top: 0;
    bottom: 0;
    background: var(--gradient);
    opacity: 0;
    transition: var(--transition);
    filter: blur(30px);
  }

  .active-element:hover {
    .active-element-content {
      background-color: var(--bg-secondary);
      &:before {
        opacity: 1;
      }
    }
  }
}