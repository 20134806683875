.pagination-wrap {
  justify-content: center;
  display: flex;
  padding-bottom: 24px;
}

.pagination {
  font-size: 14px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-flex;
  align-self: center;
  align-items: center;
  padding: 0 8px;
  height: 48px;
  border-radius: 24px;
  backdrop-filter: blur(4px);

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  li a {
    font-family: var(--font-bold);
    min-width: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--muted-color) !important;
    margin: 0 2px;
    position: relative;

    &:hover {
      color: var(--tint-color) !important;
    }
  }

  li.selected a {
    color: var(--tint-color) !important;

    &:after {
      content: '';
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: var(--tint-color);
      bottom: -15px;
      z-index: -1;
    }

  }

  li.next a {
    height: 20px;
    min-width: 20px;
    background: rgba(255, 255, 255, 0.1);
    margin-left: 4px;

    svg {
      width: 14px;

      path {
        stroke: #fff;
      }
    }
  }

  li.previous a {
    height: 20px;
    min-width: 20px;
    background: rgba(255, 255, 255, 0.1);
    margin-right: 4px;

    svg {
      width: 14px;

      path {
        stroke: #fff;
      }

    }
  }

  li.disabled {
    pointer-events: none;
    a {
      background: rgba(255, 255, 255, 0.04);
    }
    .react-icon {
      opacity: 0.20;
    }
  }

  select {
    font-size: 12px !important;
    padding-right: 20px !important;
    background-position-x: calc(100% - 4px) !important;
    background-size: 14px !important;
    height: 24px !important;
    min-height: 24px !important;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 18px;
    font-family: var(--font-refular);
    background-color: rgba(255, 255, 255, 0.1) !important;
    padding-left: 8px;
    min-width: auto;
    width: auto;
  }

}

.pagination-right {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 4px;
  padding-left: 16px;
}
