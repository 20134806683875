.auth-page {
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-auth-google {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 24px 0;

  span {
    position: relative;
    z-index: 1;
    background: var(--bg-secondary);
    padding: 0 8px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--border-color);
  }
}

.auth-page-content {
  padding: 24px;
  background: rgba(255, 255, 255, 0.08);
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.auth-recovery-success {
  position: relative;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  &:after {
    content: '';
    position: absolute;
    top: -20px;
    width: 140px;
    height: 64px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 360px;
    filter: blur(60px);
    pointer-events: none;
  }
}

.registration-coins {
  height: 56px;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, rgba(50, 163, 253, 0.6) 0, rgba(193, 254, 255, 0) 98%);
  border-radius: 56px 0 0 56px;
  padding: 8px;
  padding-right: 120px;
  position: relative;
  margin-bottom: 16px;
}
.registration-coins-gift {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 80px;
  background: url("../images/gift.png") no-repeat center center/ 32px, #32A3FD;
}
.registration-coins-text {
  flex: 1;
  padding-left: 10px;
  text-wrap: balance;
}
.registration-coins-icon {
  position: absolute;
  right: 8px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  overflow: hidden;
  width: 100px;
  span {
    position: absolute;
    transform: rotate(15deg);
    &:nth-child(1) {
      right: 12px;
      top: 12px;
      font-size: 36px;
    }
    &:nth-child(2) {
      font-size: 44px;
      right: 0;
      bottom: 0px;
    }
    &:nth-child(3) {
      font-size: 56px;
      right: 28px;
      bottom: 12px;
    }
  }
}