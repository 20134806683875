.btn {
  color: var(--text-color);
  transition: var(--transition);
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  min-width: 64px;
  //overflow: hidden;
  outline: none;
  border-radius: 16px;
  //white-space: nowrap;
  letter-spacing: 0.01em;
  flex-wrap: nowrap;
  border: none;
  box-shadow: none;
  padding: 0;

  .btn-content {
    padding: 0 32px;
    min-height: 64px;
  }
  &:focus-visible {
    box-shadow: none;
  }
  .spinner {
    bottom: 6px;
  }
}

.btn-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-text-fill-color: initial;
  &:has(.btn-icon-left.btn-icon-absolute) span {
    padding-left: 32px;
  }
  &:has(.btn-icon-left.btn-icon-absolute) span {
    padding-right: 32px;
  }
}

.btn.btn-primary {
  background: var(--gradient);

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--gradient);
    filter: blur(16px);
    transition: var(--transition);
    opacity: 0;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 24px;
    transition: var(--transition);
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    &:after {
      opacity: 1;
    }

    &:before {
      opacity: 0.5;
    }
  }

  &:active {
    &:after {
      background: rgba(18, 18, 18, 0.08);
    }

    &:before {
      opacity: 0;
    }
  }
}

.btn.btn-secondary {
  background: rgba(255, 255, 255, 0.16);

  &:hover {
    background: rgba(255, 255, 255, 0.24) !important;
  }

  &:active {
    background: rgba(255, 255, 255, 0.08) !important;
  }
}
.btn.btn-white {
  background: #fff;
  color: var(--bg-main);

  &:hover {
    background: #fff !important;
    color: var(--bg-main);
  }

  &:active {
    background: #fff!important;
    color: var(--bg-main);
  }
}
.btn.btn-danger {
  background: rgba(227, 63, 113, 0.16);
  color: var(--tint-color);

  &:hover {
    background: rgba(227, 63, 113, 0.24) !important;
    color: var(--tint-color);
  }

  &:active {
    background: rgba(227, 63, 113, 0.08) !important;
    color: var(--tint-color);
  }
}
.btn.btn-third {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 60px!important;
  color: var(--muted-color);
  border: none;
  &:hover {
    background: rgba(255, 255, 255, 0.24) !important;
  }

  &:active {
    border: none;
    background: rgba(255, 255, 255, 0.08) !important;

  }
  &.active {
    background: #fff !important;
    .btn-content {
      background: -webkit-linear-gradient(left, #E33F71, #FD7153);
      background: -o-linear-gradient(right, #E33F71, #FD7153);
      background: -moz-linear-gradient(right, #E33F71, #FD7153);
      background: linear-gradient(to right, #E33F71, #FD7153);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.btn.disabled, .btn:disabled {
  pointer-events: none;
  background: rgba(255, 255, 255, 0.04) !important;

  .btn-content {
    opacity: 0.2!important;
  }
}

.btn.loading {
  pointer-events: none;
}

.btn.btn-lg {
  min-height: 80px;
  min-width: 80px;
  font-size: 24px;
  line-height: 36px;
  border-radius: 24px;

  .btn-content {
    padding: 0 40px;
    min-height: 80px;
  }

  .react-icon svg {
    width: 40px;
    height: 40px;
  }

  .btn-icon-left {
    margin-left: -20px;
    &.btn-icon-absolute {
      left: 20px;
    }
  }

  .btn-icon-right {
    margin-right: -20px;

    &.btn-icon-absolute {
      right: 20px;
      margin-right: 0;
    }
  }
  .spinner {
    bottom: 12px;
  }
}

.btn.btn-md {
  min-height: 48px;
  min-width: 48px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 12px;
  .btn-content {
    padding: 0 24px;
    min-height: 48px;
    &:has(.btn-icon-left.btn-icon-absolute) span {
      padding-left: 24px;
    }
    &:has(.btn-icon-left.btn-icon-absolute) span {
      padding-right: 24px;
    }
  }
  .spinner {
    bottom: 3px;
  }
}

.btn.btn-sm {
  min-height: 40px;
  min-width: 40px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 12px;

  .btn-content {
    padding: 0 24px;
    min-height: 40px;
    &:has(.btn-icon-left.btn-icon-absolute) span {
      padding-left: 24px;
    }
    &:has(.btn-icon-left.btn-icon-absolute) span {
      padding-right: 24px;
    }
  }

  &:after {
    border-radius: 12px;
  }
  .btn-icon-left {
    font-size: 20px;
    margin-right: 8px;
    margin-left: -16px;
    &.btn-icon-absolute {
      left: 8px;
    }
  }
  .btn-icon-right {
    font-size: 20px;
    margin-left: 8px;
    margin-right: -16px;
    &.btn-icon-absolute {
      right: 8px;
    }
  }
  .spinner {
    bottom: 1px;
  }
}
.btn.btn-xs {
  min-height: 32px;
  min-width: 32px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 12px;

  .btn-content {
    padding: 0 20px;
    min-height: 32px;
    &:has(.btn-icon-left.btn-icon-absolute) span {
      padding-left: 24px;
    }
    &:has(.btn-icon-left.btn-icon-absolute) span {
      padding-right: 24px;
    }
  }
  svg {
    width: 16px;
    height: 16px;
  }

  &:after {
    border-radius: 12px;
  }
  .btn-icon-left {
    font-size: 16px;
    margin-left: 12px;
    &.btn-icon-absolute {
      left: 8px;
    }
  }
  .btn-icon-right {
    font-size: 16px;
    margin-left: 12px;
    &.btn-icon-absolute {
      right: 8px;
    }
  }
  .spinner {
    bottom: 1px;
  }
}

.btn-icon-left {
  font-size: 24px;
  margin-right: 16px;
  margin-left: -12px;

  &.btn-icon-absolute {
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: absolute;
    left: 20px;
  }
}

.btn-icon-right {
  font-size: 24px;
  margin-left: 16px;
  margin-right: -12px;

  &.btn-icon-absolute {
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    right: 20px;
  }
}
.btn.btn-circle {
  border-radius: 50%;
  .btn-content{
    padding: 0!important;
    justify-content: center;
    align-items: center;
  }
  .btn-icon-left, .btn-icon-right {
    margin: 0;
  }
}