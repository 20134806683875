.home-page {
  padding: 0 24px;
  //padding: 0 40px;
  //padding-left: 80px;

}
.home-page h1{
  width: 550px;
  max-width: 100%;
  margin-top: 40px;
}
.home-top-text {
  margin-top: 24px;
}
.home-top-left .btn {
  margin-top: 48px;
}

.home-top-container {
  display: flex;
  user-select: none;
}

.home-top-left {
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top-carousel {
  position: relative;
  width: 606px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 80px;
}

.top-carousel-item {
  position: absolute;
  transition: var(--transition);
  width: 314px;

  &.left {
    transform: translate(-150px, 30px) rotate(-16deg);

    &:hover {
      transform: translate(-190px, 40px) rotate(-20deg);
    }
  }

  &.right {
    transform: translate(150px, 30px) rotate(16deg);

    &:hover {
      transform: translate(190px, 40px) rotate(20deg);
    }
  }

  &.center {
    z-index: 1;

    .top-carousel-item-chat {
      display: block;
    }
  }

  &.center {
    .top-carousel-item-avatar {
      -webkit-mask-image: none;
      mask-image: none;

      &:after {
        opacity: 1;
      }
    }

    .top-carousel-item-about {
      opacity: 1;
    }
  }
  .chat-board-model {
    display: none;
  }
}

.top-carousel-item-avatar {
  position: relative;
  width: 314px;
  height: 314px;
  border-radius: 32px;
  -webkit-mask-image: linear-gradient(180deg, var(--bg-main) 35%, rgba(0, 0, 0, 0) 100%);
  mask-image: linear-gradient(180deg, var(--bg-main) 35%, rgba(0, 0, 0, 0) 100%);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 50%;
    background: linear-gradient(to bottom, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.8) 100%);
    opacity: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

.top-carousel-item-about {
  position: absolute;
  bottom: 26px;
  left: 24px;
  z-index: 1;
  opacity: 0;
}

.home-top-footer {
  display: flex;
  align-items: center;
  margin-top: 48px;

  .timer {
    margin-left: 12px;
  }
}

.top-carousel-item-chat {
  display: none;
  width: 100%;
}

.top-carousel-item-chat-board {
  position: relative;
  .message-container {
    .message {
      background-color: var(--bg-main);
    }

    &:last-child .message {
      border-bottom-left-radius: 4px;
    }
  }
}



.top-carousel-item-chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 80px;
  position: absolute;
  bottom: 0;
  left: -44px;

  img {
    border-radius: 80px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.model-style {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  height: 64px!important;

  .horizontal-scroll {
    padding: 0;
    justify-content: center;
    gap: 8px;
  }
  .horizontal-scroll-arrow {
    display: none;
  }

  .btn {
    min-width: 163px;
  }
}

.top-carousel-bg {
  position: absolute;
  z-index: -1;
  width: 700px;
  bottom: -170px;
  opacity: 0.8;
}

.home-craft {
  position: relative;
  padding: 144px 0 64px;
  display: flex;
  justify-content: center;
  margin-top: 0;

  .btn {
    margin-top: 40px;
  }
}

.home-craft-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  height: 100%;
  width: 100%;
  pointer-events: none;

  img {
    width: 1300px;
  }
}

.home-craft-bg_bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(227, 63, 113, 0.32);
    border-radius: 80px;
    filter: blur(100px);
  }
}

.home-faq {
  width: 50%;
}


.home-faq-item {
  position: relative;
  /*background: rgba(255, 255, 255, 0.05);*/
  background: var(--bg-secondary);
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  margin-top: 16px;
  transition: var(--transition);

  &:hover {
    background: var(--bg-secondary-hover);

    .home-faq-item-head {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    background: var(--gradient);
    z-index: -1;
    border-radius: 34px;
    transition: var(--transition);
    opacity: 0;
  }
}

.home-faq-item.active {
  background: var(--bg-secondary);

  &:before {
    opacity: 1;
  }

  .home-faq-item-head {
    opacity: 1;

    .react-icon {
      transform: rotate(180deg);
    }
  }

  .home-faq-item-content {
    transition: opacity .3s ease;
    transform: scale(1);
    opacity: 1;
    max-height: 200vh;
    display: block;
    margin-top: 0;
    padding: 0 32px 24px;
  }
}

.home-faq-item-head {
  transition: var(--transition);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 24px 32px;
  opacity: 0.8;
  position: relative;
  z-index: 1;
}

.home-faq-item-head .react-icon {
  transition: var(--transition);
}

.home-faq-item-content {
  transform: scale(0);
  opacity: 0;
  max-height: 0;
  font-size: 16px;
  line-height: 24px;
  //color: var(--muted-color);

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--gradient);
    border-radius: 360px;
    filter: blur(120px);
    z-index: -1;
    pointer-events: none;
  }
  ol {
    margin-top: 8px;
    font-size: 14px;
    padding-left: 12px;
    line-height: 20px;
    li {
      margin-top: 4px;
    }
  }
}

.home-description {
  width: 50%;

  h5 {
    padding: 24px;
    padding-bottom: 0;
  }
}

.home-description-container {
  background: var(--bg-secondary);
  border-radius: 32px;
  margin-top: 32px;
  overflow: hidden;
  padding: 24px;
}

.home-footer-section {
  padding: 0 24px 24px;
}

.home-footer {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 32px;
  padding: 34px 24px 24px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 400px;
    height: 270px;
    border-radius: 50% 50% 100px 100px;
    background: var(--gradient);
    opacity: 0.5;
    filter: blur(100px);
    bottom: -50px;
    z-index: -1;
  }
}

.home-footer-logo {
  height: 52px;
}

.home-footer-payment {
  height: 64px;
  border: 1px solid var(--border-color);
  width: 112px;
  margin-left: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  img {
    height: 100%;
  }
}

.home-footer-bottom {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
}

.home-models {
  //overflow: hidden;
  z-index: 1;
  margin-top: 120px;
}

.home-models-list {
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
  margin-top: 28px;
  padding-bottom: 42px;

  .active-element-container {
    width: 25%;
    min-width: 25%;
    padding: 12px;

    &:nth-child(2n) {
      transform: translateY(40px);
    }
  }
}

.home-slider {
  display: none;
  margin-top: 16px;
  .slick-slide {
    display: flex;
    justify-content: center;
  }
}
.top-carousel-item-message-name {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-montserrat-bold);
  margin-bottom: 8px;
  display: none;
}

.home-top-gender {
  display: flex;
  justify-content: flex-start;
}

.home-footer-contacts {
  margin-top: 24px;
  &.bottom {
    display: none;
  }
}
.home-models .model-card .model-card-category {
  max-width: calc(100% - 76px);
}
.home-tags {
  justify-content: center;
  position: relative;
  margin-top: 24px;
  padding: 16px;
  padding-bottom: 0;
  margin-bottom: -16px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 440px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 32px 32px 0 0;
    pointer-events: none;
    z-index: -1;
  }
}