@media only screen and (max-width: 1199px) {
  .model-profile-head {
    display: flex;
    height: 88px;
  }
}

@media only screen and (max-width: 991px) {
  .model-profile-info {
    padding: 16px;
  }
  .model-profile-page .model-profile-slider-small {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .model-profile-head {
    padding: 16px;
    height: auto;
  }

}

@media only screen and (max-width: 575px) {

  .model-profile-page .model-profile-slider-main .model-profile-slide {
    padding: 0;

    img {
      width: 100%;
    }
  }
}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}
