.my-ai-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: -12px;
  min-height: 450px;

  > div {
    width: 25%;
    padding: 12px;
  }
}

.create-my-ai-container {
  display: flex;
  aspect-ratio: 1/1.5;
}

.create-my-ai {
  cursor: pointer;
  display: flex;
  flex: 1;
  border-radius: 32px;

  transition: var(--transition);
  position: relative;

  &:hover {
    padding: 8px;
    border-color: transparent;

    .create-my-ai-content {
      background: rgba(255, 255, 255, 0.08);
    }

    .create-my-ai-plus:before {
      opacity: 1;
    }

    .create-my-ai-wand > div {
      transform: scale(1.2);
    }

    &:before {
      background: var(--gradient);
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.4);
    transition: var(--transition);
  }

  &:after {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    top: -1px;
    border-radius: 32px;
    border: 5px dashed var(--bg-main);
    transition: var(--transition);
  }
}

.create-my-ai-bg {
  position: absolute;
  left: 1px;
  right: 1px;
  bottom: 1px;
  top: 1px;
  border-radius: 32px;
  background: var(--bg-main);
}

.create-my-ai-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  border-radius: 24px;
  position: relative;
}

.create-my-ai-wand {
  position: relative;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-my-ai-wand > div {
  position: absolute;
  transition: var(--transition);
}

.create-my-ai-plus {
  background: var(--gradient);
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  position: relative;

  > div {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    background: var(--gradient);
    filter: blur(16px);
    transition: var(--transition);
    opacity: 0;
    pointer-events: none;
  }
}

.my-ai-process {
  position: relative;
  background: rgba(255, 255, 255, 0.04);
  flex: 1;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--gradient);
    border-radius: 40px;
    filter: blur(30px);
    z-index: -1;
    opacity: 0.6;
  }
}

.my-ai-process-text {
  position: absolute;
  bottom: 40px;
  text-align: center;
  padding: 0 16px;
}

.my-ai-process-bg {
  position: absolute;
  animation: my-ai-process-bg 4s infinite;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    width: 230px;
    height: 80px;
    border-radius: 50px;
    transform: rotate(-45deg);
    background: var(--secondary-color);
    opacity: 0.64;
    filter: blur(20px);
    animation: my-ai-process-bg-rotate 4s infinite;
  }

  &:after {
    content: '';
    position: absolute;
    width: 230px;
    height: 80px;
    border-radius: 50px;
    transform: rotate(45deg);
    background: var(--tint-color);
    opacity: 0.64;
    filter: blur(20px);
    animation: my-ai-process-bg-rotate-2 4s infinite;
  }
}

@keyframes my-ai-process-bg-rotate {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes my-ai-process-bg-rotate-2 {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(135deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(225deg);
  }
}

@keyframes my-ai-process-bg {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.4);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.my-ai-process-text-dots {
  position: absolute;
  left: 100%;
  bottom: 0;
}

.my-ai-progress-bar {
  position: absolute;
  width: 90%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: var(--transition);
}

.my-ai-progress-bar-loader {
  width: 90%;
  aspect-ratio: 1;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;

  position: absolute;

  &:after {
    content: '';
    animation: rotation 5s linear infinite;
    box-sizing: border-box;
    position: absolute;
    inset: -2px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: var(--tint-color);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.stick_star-1 {
  margin-left: 20px;
  margin-top: 10px;
  transition: var(--transition);
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.stick_star-2 {
  margin-left: 32px;
  margin-top: -8px;
  transition: var(--transition);
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  svg {
    width: 10px;
    height: 10px;
  }
}

.stick_star-3 {
  margin-left: -22px;
  margin-top: -30px;
  transition: var(--transition);
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.my-ai-progress-finished {
  position: absolute;
  width: calc(90% - 28px);
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--tint-color);
  border-radius: 50%;
  transition: var(--transition);
  opacity: 0;

  svg {
    width: 56px;
    height: 56px;
  }
}

.my-ai-process.finished {
  .my-ai-progress-bar {
    opacity: 0;
  }

  .my-ai-progress-finished {
    opacity: 1;
  }

  .my-ai-process-text-dots {
    display: none;
  }
}


.my-ai-generate-progress {
  height: 1px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.08);
  z-index: 1;
  display: flex;
  align-items: center;
  margin-top: 8px;
  bottom: 23px;
  position: absolute;
  left: 32px;
  right: 32px;
}

.my-ai-generate-progress-percent {
  height: 3px;
  background: var(--gradient);
  border-radius: 2px;
}

