@media only screen and (max-width: 1700px) {
  .chat-board-header-left {
    flex-direction: column;
  }
  .chat-board-model {
    border-radius: 32px;
    padding-right: 24px;
    margin-right: 0;
  }
  .chat-board-header:has(.loyalty-system-container.cold) .chat-board-header-left {
    background-color: #1C233D;
  }
  .chat-board-header:has(.loyalty-system-container.friendly) .chat-board-header-left {
    background-color: #4B4222;
  }
  .chat-board-header:has(.loyalty-system-container.horny) .chat-board-header-left {
    background-color: #3D231E;
  }
  .chat-board-header:has(.loyalty-system-container.loving) .chat-board-header-left {
    background-color: #381A1A;
  }
  .loyalty-system {
    width: 100%;
    padding: 16px;
    padding-top: 0;
  }
  .loyalty-system-container {
    width: 100%;
  }
  .chat-board {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 1800px) {
  .chats-section {
    width: 320px;
  }

}

@media only screen and (max-width: 1521px) {
  .chats-section {
    width: 280px;
  }
}

@media only screen and (max-width: 1441px) {
  .chat-board-model-arrow {
    display: flex;
  }
  .chats-section {
    width: 320px;
  }
  .chats-section.model-profile {
    display: none;
  }
  .chat-board-model {
    left: 0;
    position: relative;
    right: 0;
    margin: 0;
    width: 100%;
  }
  .chat-container {
    margin-top: 16px;
    margin-right: 16px;
    border-radius: 40px 40px 0 0;
  }
}

@media only screen and (max-width: 1700px) and (min-width: 992px) {
  .loyalty-system-status-item-name {
    width: 50px;
  }
  .loyalty-system-status-item {
    &:before {
      width: 15px;
      height: 15px;
    }
  }
  .loyalty-system-header-arrow {
    width: 24px;
    height: 24px;
    background: url("../../../../assets/icons/expand.svg") no-repeat center center /12px, rgba(255, 255, 255, 0.1);

    div {
      display: none;
    }
  }
  .chat-page {
    .loyalty-system-container {
      padding: 0 4px;
    }

    .loyalty-system-header {
      height: 32px;
    }

    .loyalty-system-status {
      padding-right: 46px;
      left: 8px;
    }

    .loyalty-system-status-item-icon {
      height: 17px;
      width: 17px;
      font-size: 10px;
    }

    .loyalty-system-status-progress {
      width: calc(33.33% - 17px);
      left: 15px;
    }

    .loyalty-system-status-item:nth-child(2n) .loyalty-system-status-progress {
      left: calc(33.33% + 10px);
    }

    .loyalty-system-status-item:nth-child(3n) .loyalty-system-status-progress {
      left: calc(66.66% + 4px);
    }

    .loyalty-system-container {
      min-width: 200px;
      border-radius: 28px 28px;
    }

    .loyalty-system-header-title, .loyalty-system-about-title {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .chats-section {
    width: 290px;
  }
  .chat-page-models-list {
    padding-bottom: 24px;
  }
}

@media only screen and (max-width: 1199px) {
  .chats-section .header-secondary {
    display: flex !important;
  }
  .chat-container .header-secondary {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {

  .loyalty-system-header {
    min-height: 32px;
    height: auto;
  }
  .chat-board-header {
    right: 16px;
    justify-content: center;
  }
  .chat-board-header-left {
    align-items: center;
    width: 320px;
  }
  .chat-top-controls {
    margin-top: 0;
  }
  .chat-board-container .chat-settings {
    display: none;
  }
  .chat-board-container .chat-settings-tg {
    display: none;
  }
  .loyalty-system {
    padding: 0;
  }
  .loyalty-system-container {
    min-width: auto;
  }
  .loyalty-system-status {
    padding: 8px;
    left: 0;

  }
  .loyalty-system-container.visible .loyalty-system-status {
    padding-left: 16px;
    padding-right: 16px;
  }
  .loyalty-system-header-arrow {
    display: none;
  }
  .chat-board-model {
    height: 56px;
    padding: 4px 32px 4px 4px;
    font-size: 14px;
    line-height: 20px;
  }

  .chat-input {
    .form-control {
      padding-right: 84px;
      padding-left: 56px !important;

      &::placeholder {
        font-family: var(--font-regular);
      }
    }

    .btn {
      height: 48px;
      min-height: 48px;
      min-width: 48px;
      border-radius: 50%;
    }

    .avatar {
      left: 12px;
    }
  }
  .chat-control {
    padding: 0 16px 16px;
  }
  .chat-page.chat-page-type-chat {
    .chats-section {
      display: none;
    }

    .header-secondary {
      display: flex !important;
    }
  }
  .chat-page.chat-page-type-list {
    .chat-container {
      display: none;
    }
  }
  .chats-section {
    overflow: hidden;
    width: 100%;
    background: rgba(255, 255, 255, 0.08);

  }
  .chat-page {
    overflow: visible;
    margin: 0;
    background-color: transparent;
    width: 100%;
  }
  .chats-section-title {
    display: none;
  }
  .chats-side-list {
    top: 0;
    padding: 0;
    &::-webkit-scrollbar-track {
      margin-top: 40px;
    }
    .px-12 {
      padding: 0;
    }
  }
  .chats-list-item {
    padding-left: 16px;
    padding-right: 16px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    &.active {
      background: transparent;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    &:before {
      left: 80px;
      right: 16px;
      bottom: 0;
      top: auto;
      opacity: 1 !important;
      display: block !important;
    }

  }
  .chats-list-item.active:before, .chats-list-item.active + .chats-list-item:before {
    opacity: 1;
  }
  .chat-container {
    margin: 0;
    overflow: hidden;
    border: 1px solid var(--border-color);
    border-radius:  32px 32px 0 0;
    .header-secondary h2 {
      font-family: var(--font-bold);
      font-size: 16px;
      line-height: 24px;
    }


    .chat-board-model {
      display: none;
    }
  }
  .chat-board {
    //position: relative!important;
    //overflow: visible;
    //padding-top: 0;
    padding-bottom: 16px;
    padding-top: 80px;

    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    border-radius: 32px;
    &::-webkit-scrollbar-track {
      margin-bottom: 16px;
    }
  }
  .chat-board-scroll {
    padding-top: 0;
  }
  .chat-control {
    .form-control-wrap {
      border-radius: 42px;

    }

    .form-control {
      min-height: 56px !important;
      border-radius: 42px !important;
    }

    .form-control-bg {
      border-radius: 42px !important;

      &:before {
        border-radius: 45px !important;
      }

      &:after {
        border-radius: 42px !important;
      }
    }
  }
  main.main-chat {
    overflow: hidden;
    padding-bottom: 0;

    & + footer {
      display: none;
    }
  }
  .chat-confirm-image {
    padding: 16px;
  }

}

@media only screen and (max-width: 767px) {
  .chats-section {
    border-radius: 32px 32px 0 0;
  }
  .chat-page-models-list {
    padding-bottom: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .loyalty-system-status-item {
    &:first-child .loyalty-system-status-item-about {
      left: -16px;
    }

    &:last-child .loyalty-system-status-item-about {
      right: -16px;
    }
  }
  .chat-board-header-left {
    width: 100%;
  }
  .chat-confirm-image-footer {
    flex-direction: column;
    gap: 8px;

    .btn, .w-50 {
      margin: 0 !important;
      width: 100% !important;
    }
  }
  .chat-board {
    padding-left: 16px;
    padding-right: 16px;

    .avatar {
      display: none;
    }
  }
}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}
