@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .generate-prompt-tabs-list {
    //gap: 4px;
    .btn {

      //font-size: 12px;
      //min-height: 24px;
      //height: 24px;
      //.btn-content {
      //  min-height: 24px;
      //  height: 24px;
      //}
      .btn-content {
        padding-right: 20px;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .generate-image-count-list .border-gradient-secondary {
    flex: 1;
    min-width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .generate-image-count-list .border-gradient-secondary {
    flex: initial;
    min-width: 157px;
  }
  .create-image-page {
    padding: 0 !important;

    .card {
      border-radius: 32px 32px 0 0 !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .generate-model-btns {
    flex-direction: column;
    gap: 16px;
  }
}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}