@media only screen and (max-width: 1199px) {
  .shared-chats-page {
    padding: 0 0 16px;
  }
}

@media only screen and (max-width: 991px) {
  .shared-chats-page {
    min-height: calc(100dvh - 90px - 88px);
    .pagination-wrap {
      margin-top: 16px;
    }
  }
  .shared-list-item {
    margin: 0;
    position: relative;
    .btn-expand-container {
      position: absolute;
      margin: 0;
      top: 16px;
      left: 16px;
      .avatar {
        min-width: 64px;
        width: 64px;
        height: 64px;
      }
    }
  }
  .shared-list-item-body-container {
    margin: 0;
  }
  .shared-list-item-body {
    padding: 16px;
  }
  .shared-list-item-head {
    padding-left: 72px;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8px;
    .name-age {
      font-size: 16px;
      line-height: 24px;
    }
    .mt-2 {
      margin-top: 4px!important;
    }
  }
  .shared-list-item-head-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .name-age {
      white-space: nowrap;
    }
    .model-card-category {
      top: auto;
      right: auto;
      position: relative;
      margin-left: 12px;
      display: grid;
    }
  }
}

@media only screen and (max-width: 767px) {
  .shared-chats-page .model-style .horizontal-scroll {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}