.legal-btn-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  .btn {
    width: calc(25% - 16px);
  }
}

.legal-information-date {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.legal-information-content {
  p {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    color: var(--muted-color);
  }

  ul {
    list-style-type: lower-alpha;
    padding-left: 16px;
    color: var(--muted-color);
    font-size: 14px;
    line-height: 20px;
    &.upper {
      list-style-type: upper-alpha;
    }
    li {
      margin-bottom: 8px;
    }
  }
  b {
    color: rgba(255,255,255,0.9);
  }

  h4 {
    font-family: var(--font-bold);
    margin-top: 12px;
    margin-bottom: 16px;
  }
}