
.horizontal-scroll-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
  &:after {
    content: '';
    position: absolute;
    right: -1px;
    bottom: 0;
    top: 0;
    width: 48px;
    background: linear-gradient(270deg, var(--bg-main) 20%, rgba(18, 18, 18, 0) 100%);
    pointer-events: none;
    z-index: 3;
    opacity: 0;
  }
  &:before {
    content: '';
    position: absolute;
    left: -1px;
    bottom: 0;
    top: 0;
    width: 48px;
    background: linear-gradient(to right, var(--bg-main) 20%, rgba(18, 18, 18, 0) 100%);
    pointer-events: none;
    z-index: 3;
    opacity: 0;
  }
  &.show-arrow-left:before {
    opacity: 1;
  }
  &.show-arrow-right:after {
    opacity: 1;
  }
}
.horizontal-scroll-secondary {
  &:before {
    background: linear-gradient(to right, var(--bg-secondary) 20%, rgba(18, 18, 18, 0) 100%);
  }
  &:after {
    background: linear-gradient(270deg, var(--bg-secondary) 20%, rgba(18, 18, 18, 0) 100%);
  }
}
.horizontal-scroll {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
overflow: hidden;
  padding-right: 48px;
  &::-webkit-scrollbar {
    height: 0!important;
  }
  .btn {
    white-space: nowrap;
  }
}

.horizontal-scroll-arrow {
  position: absolute;
  backdrop-filter: blur(16px);
  z-index: 4;
  min-width: 40px!important;
  width: 40px!important;
  &.arrow-left {
    left: 0;
  }
  &.arrow-right {
    right: 0;
  }
}