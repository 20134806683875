@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .gender-select {
    height: 56px;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
  .gender-select {
    width: 100%;
  }
  .gender-select-tab {
    flex: 1;
    justify-content: center;
  }
}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}