
.list-pay-card {
  height: 44px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: var(--transition);
  position: relative;
  padding: 0 12px;
  &:before {
    content: '';
    position: absolute;
    border-radius: 16px;
    inset: 0;
    background: var(--gradient);
    transition: var(--transition);
    opacity: 0;
  }

  &:after {
    content: '';
    position: absolute;
    border-radius: 16px;
    inset: 1px;
    background: var(--bg-secondary);
    transition: var(--transition);
  }
  .form-radio-custom {
    width: 20px;
    height: 20px;
    min-width: 20px;
    background: transparent;
    border-width: 1px;

  }
  .form-radio {
    pointer-events: none;
  }
  &:hover:before {
    opacity: 1;
  }

  &.active {
    &:before {
      opacity: 1;
    }
    &:after {
      opacity: 0.76;
    }

  }
}
.list-pay-card.edit:after {
  background: #3C3C3C;

}
.list-pay-card-status {
  margin-right: 12px;
}

.list-pay-card-image {
  //background: rgba(255, 255, 255, 0.1);
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  img {
    height: 100%;
  }
}

.list-pay-card-trash {
  cursor: pointer;
  background: rgba(227, 63, 113, 0.16);
  margin-left: 4px;
  width: 44px;
  min-width: 44px;
  height: 44px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}