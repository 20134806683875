@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
  .create-my-ai-container {
  min-height: 204px;
  }
  .create-my-ai-plus {
    width: 64px;
    height: 64px;
    margin-top: 24px;
  }

}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}