.chat-page {
  align-self: center;
  display: flex;
  flex: 1;
  background: var(--bg-secondary);
  margin: 0 24px;
  border-radius: 48px 48px 0 0;
  overflow: hidden;
  //min-width: 2000px;
  width: calc(100% - 48px);
  max-width: 1800px;

  .header-secondary {
    display: none !important;
  }
}

.chats-section {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 368px;
  border-radius: 32px 32px 0 0;

  .model-profile-slide img {
    width: 100%;
  }
}


.chats-side-list-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chats-side-list {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0px;
  top: 16px;
  overflow: auto;
  padding-top: 68px;

  &::-webkit-scrollbar-track {
    margin-top: 68px;
  }
}

.chats-list-item {
  padding: 12px;
  border-radius: 16px;
  transition: var(--transition);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.05);

    &:before, & + .chats-list-item:before {
      opacity: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    left: 12px;
    right: 12px;
    top: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    transition: var(--transition);
  }

  &.active {
    background: rgba(255, 255, 255, 0.1);

    &:before, & + .chats-list-item:before {
      opacity: 0;
    }
  }

  &:first-child:before {
    display: none;
  }
}

.chats-list-item-message {
  margin-top: 2px;
  display: flex;
  overflow: hidden;
  align-items: center;
  color: rgba(255, 255, 255, 0.64);

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 2px;
  }
  svg {
    width: 16px;
    height: 16px;
  }
}

.chat-container {
  //overflow: hidden;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: url("../images/bg.png") repeat center center/115px, var(--bg-main);
  border-radius: 48px 48px 0 0;

  .header-secondary h2 {
    font-family: var(--font-bold);
  }
}

.chat-board-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.chat-board {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  overflow-y: auto !important;
  padding: 96px 76px 24px;
  //scroll-behavior: smooth;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar-track {
    margin-top: 40px;
    margin-bottom: 24px;
  }
}

.chat-board-scroll {

}

.chat-control {
  padding: 0 24px 24px;
  position: relative;
  width: 100%;
  transition: var(--transition);
  //overflow: hidden;
  &:before {
    content: '';
    transition: var(--transition);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    inset: -100px 0 0 0;
    background: linear-gradient(360deg, rgba(68, 108, 252, 0.3) 0%, rgba(68, 108, 252, 0) 100%);
  }
}

.chat-control.confirm-visible {
  .chat-input {
    top: (calc(100% + 125px))
    //transform: translateY(300px);
  }

  .chat-confirm-image {
    transform: translateY(0px);
  }
}

.chat-input {
  position: relative;
  width: 100%;
  transition: var(--transition);
  transform: translateY(0);
  .form-control {
    padding-right: 84px;
    padding-left: 68px !important;
    font-family: var(--font-regular);

    &::placeholder {
      font-family: var(--font-regular);
    }
  }

  .btn {
    position: absolute;
    right: 4px;
    bottom: 4px;
    z-index: 1;
    border-radius: 20px;
  }

  .avatar {
    position: absolute;
    left: 20px;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.16);
    z-index: 1;
  }
}


.chats-section-title {
  pointer-events: none;
  position: absolute;
  top: 0;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 2;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 0 0 32px 32px;

  span {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -24px;
    top: 0px;
    background: linear-gradient(to top, transparent 24px, var(--bg-secondary) 48px, var(--bg-secondary) 100%);
  }
}


.chat-settings {
  background-color: var(--bg-secondary);
  cursor: pointer;
  border-radius: 50%;
  margin-left: 8px;
}

.chat-top-controls-profile {
  cursor: pointer;
  display: none;
  margin-right: 16px;
  padding: 0 20px 0 8px;
  height: 40px;
  border-radius: 12px;
  background-color: var(--bg-secondary);
  transition: var(--transition);
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--bg-secondary-hover);
  }
}

.chat-settings-tg {
  height: 100%;
  aspect-ratio: 1;
  background: url('../../../../assets/icons/tg.svg') no-repeat center / 24px, #446CFC;
  border-radius: 50% !important;
  &:hover {
    background: url('../../../../assets/icons/tg.svg') no-repeat center / 24px, #5A7EFC;
  }
}

.chat-settings.visible {
  .chat-settings-btn {
    background: rgba(255, 255, 255, 0.08);
  }
}

.chat-settings-btn {
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: var(--transition);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--bg-secondary-hover);
  }
}

.chat-confirm-image {
  z-index: 1;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  backdrop-filter: blur(16px);
  border-radius: 24px;
  padding: 24px 16px 16px;
  transition: var(--transition);
  transform: translateY(300px);

  &:before {
    content: '';
    width: 140px;
    height: 64px;
    background: rgba(255, 255, 255, 0.5);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    z-index: -1;
    filter: blur(60px);
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn {
    white-space: pre-wrap;
    line-height: 15px;

    .btn-content {
      padding: 0 12px;
    }
  }

  .btn-secondary {
    padding-right: 32px;
  }

  //transform: translateY(80px);
}

.chat-confirm-image-footer {
  display: flex;
  margin-top: 24px;
}

.chat-control .timer-item {
  position: absolute;
  right: 4px;
  pointer-events: none;
  width: 32px !important;
  height: 32px !important;
  font-size: 14px !important;
  border-radius: 8px !important;
}


.chat-board-header {
  z-index: 2;
  position: absolute;
  top: 16px;
  left: 16px;
  right: 28px;
  height: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.chat-board-header-left {
  border-radius: 32px;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.chat-board-header:has(.loyalty-system-container.cold) .chat-board-model {
  background-color: #1C233D;
}

.chat-board-header:has(.loyalty-system-container.friendly) .chat-board-model {
  background-color: #4B4222;
}

.chat-board-header:has(.loyalty-system-container.horny) .chat-board-model {
  background-color: #3D231E;
}

.chat-board-header:has(.loyalty-system-container.loving) .chat-board-model {
  background-color: #381A1A;
}

.chat-board-model {
  display: flex;
  align-items: center;
  padding: 8px 48px 8px 8px;
  border-radius: 32px 0 0 32px;
  margin-right: -32px;
  white-space: nowrap;
}

.chat-top-controls {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 8px;
  margin-top: 8px;
  height: 48px;
}

.loyalty-system-container {
  width: 390px;
  min-width: 390px;
  position: relative;
  border-radius: 32px;
  align-self: flex-start;
  z-index: 3;
  padding: 8px;
}

.loyalty-system-container.cold {
  background-color: #1F284B;

  .loyalty-system-status-item-icon {
    background-color: #333950;
  }

  .loyalty-system-about {
    background: rgba(68, 108, 252, 0.24);
  }

  .loyalty-system-about-title {
    color: #446CFC;
  }

  .loyalty-system-about-btn {
    background: #446CFC;

    &:hover {
      background: #5A7EFC;
    }

    &:active {
      background: #3E61E0;
    }
  }
}

.loyalty-system-container.friendly {
  background-color: #5D5127;

  .loyalty-system-status-item-icon {
    background-color: #6D623D;
  }

  .loyalty-system-about {
    background: rgba(253, 216, 83, 0.24);
  }

  .loyalty-system-about-title {
    color: #FDD853;
  }

  .loyalty-system-about-btn {
    background: #FDD853;
    color: var(--bg-main);

    &:hover {
      background: #FDDD68;
    }

    &:active {
      background: #E1C04B;
    }
  }
}

.loyalty-system-container.horny {
  background-color: #4A2922;

  .loyalty-system-status-item-icon {
    background-color: #5C3E38;
  }

  .loyalty-system-about {
    background: rgba(253, 113, 83, 0.24);
  }

  .loyalty-system-about-title {
    color: #FD7153;
  }

  .loyalty-system-about-btn {
    background: #FD7153;

    &:hover {
      background: #FD8268;
    }

    &:active {
      background: #E1664B;
    }
  }
}

.loyalty-system-container.loving {
  background-color: #441D1D;

  .loyalty-system-about {
    background: rgba(227, 63, 63, 0.24);
  }

  .loyalty-system-about-title {
    color: #E33F3F;
  }

  .loyalty-system-about-btn {
    display: none;
  }
}

.loyalty-system-container.visible {
  .loyalty-system-status {
    pointer-events: auto;
    transition: var(--transition);
    position: relative;
    inset: 0;
    background: rgba(18, 18, 18, 0.24);
    width: 100%;
    padding: 24px 32px 32px;
    margin-top: 4px;
  }

  .loyalty-system-header-title {
    transition: var(--transition);
    opacity: 1;
  }

  .loyalty-system-header-arrow {
    transform: rotate(180deg);
  }

  .loyalty-system-status-item.current .loyalty-system-status-progress-percent-value {
    display: flex;
  }

  .loyalty-system-status-item-name {
    display: grid;
  }

  .loyalty-system-about {
    display: flex;
  }

  .loyalty-system-status-item-percent {
    display: block;
  }
}

.loyalty-system-header {
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loyalty-system-header-title {
  opacity: 0;
  font-family: var(--font-bold);
}

.loyalty-system-header-arrow {
  cursor: pointer;
  position: absolute;
  right: 0px;
  height: 48px;
  width: 48px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  opacity: 0.8;
}

.loyalty-system-status {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 16px;
  border-radius: 24px;
  padding-right: 92px;
}

.loyalty-system-status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    filter: blur(8px);
    z-index: -1;
  }
}

.modal-loyalty-system .loyalty-system-status-item {
  &:first-child .loyalty-system-status-item-about {
    left: -16px;
  }

  &:last-child .loyalty-system-status-item-about {
    right: -16px;
  }
}

.loyalty-system-status-item.cold {
  &.current:before {
    background: #446CFC;
  }

  &.active {
    .loyalty-system-status-item-icon {
      background: linear-gradient(90deg, #446CFC 0%, #7086D4 100%);
    }
  }

  .loyalty-system-status-progress-percent {
    background: linear-gradient(90deg, #7086D4 0%, #FDB453 100%);
  }

}

.loyalty-system-status-item.friendly {
  &.current:before {
    background: #FDB453;
  }

  &.active {
    .loyalty-system-status-item-icon {
      background: linear-gradient(270deg, #FDB453 0%, #EFD061 100%);
    }
  }

  .loyalty-system-status-progress-percent {
    background: linear-gradient(90deg, #EFD061 0%, #F15A4A 100%);
  }

}

.loyalty-system-status-item.horny {
  &.current:before {
    background: #F15A4A;
  }

  &.active {
    .loyalty-system-status-item-icon {
      background: linear-gradient(270deg, #F15A4A 0%, #FD7052 100%);
    }
  }

  .loyalty-system-status-progress-percent {
    background: linear-gradient(90deg, #FD7052 0%, #E33F3F 100%);
  }
}

.loyalty-system-status-item.loving {
  &.current:before {
    background: #E33F3F;
  }

  &.active {
    .loyalty-system-status-item-icon {
      background: linear-gradient(270deg, #E33F3F 0%, #E44040 100%);
    }
  }

  .loyalty-system-status-progress {
    display: none !important;
  }
}

.loyalty-system-status-item.active {
  .loyalty-system-status-item-icon span {
    filter: none;
  }
}

.loyalty-system-status-item-icon {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 2;
  position: relative;
  transition: var(--transition);

  span {
    filter: grayscale(100%);
  }
}

.loyalty-system-status-progress {
  pointer-events: none;
  position: absolute;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  width: calc(33.33% - 40px);
  left: 32px;
}

.loyalty-system-status-item:nth-child(2n) .loyalty-system-status-progress {
  left: calc(33.33% + 20px);
}

.loyalty-system-status-item:nth-child(3n) .loyalty-system-status-progress {
  left: calc(66.66% + 10px);
}

.loyalty-system-status-progress-percent {
  position: absolute;
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  display: none;
  transition: var(--transition);
  min-width: 4px;
}

.loyalty-system-status-item.active {
  .loyalty-system-status-progress-percent {
    display: block;
  }

  &:not(.current) {
    .loyalty-system-status-progress-percent {
      width: 100% !important;
    }
  }
}

.loyalty-system-status-progress-percent-value {
  width: 1px;
  position: absolute;
  bottom: 21px;
  display: none;
  align-items: center;
  justify-content: center;
  font-family: var(--font-bold);
  font-size: 10px;
  line-height: 12px;
  right: 0;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    top: 100%;
    background-color: #fff;
  }
}

.loyalty-system-status-item-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loyalty-system-status-item-name {
  font-size: 10px;
  font-family: var(--font-bold);
  line-height: 15px;
  padding-top: 4px;
  text-align: center;
  position: absolute;
  top: 100%;
  transition: var(--transition);
  display: none;
  width: 100px;

}

.loyalty-system-status-item-percent {
  position: absolute;
  bottom: 100%;
  font-size: 10px;
  font-family: var(--font-bold);
  line-height: 15px;
  text-align: center;
  display: none;
}

.loyalty-system-about {
  padding: 12px 16px 16px;
  border-radius: 24px;
  display: none;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 4px;
}

.loyalty-system-about-title {
  font-family: var(--font-bold);
}

.loyalty-system-about-btn {
  padding: 0 24px;
  border-radius: 20px !important;
  margin-top: 16px;
}

.loyalty-system-status-item-about {
  position: absolute;
  top: calc(100% + 24px);
  font-size: 10px;
  line-height: 15px;
  width: 180px;
  padding: 4px 12px;
  border-radius: 12px;
  pointer-events: none;
  display: none;
  text-align: center;
}

.loyalty-system-status-item:hover {
  &.cold {
    .loyalty-system-status-item-name {
      color: #446CFC;
    }

    .loyalty-system-status-item-about {
      display: block;
      background: #1F284B;
    }
  }

  &.friendly {
    .loyalty-system-status-item-name {
      color: #FDD853;
    }

    .loyalty-system-status-item-about {
      display: block;
      background: #5D5127;
    }
  }

  &.horny {
    .loyalty-system-status-item-name {
      color: #FD7153;
    }

    .loyalty-system-status-item-about {
      display: block;
      background: #4A2922;
    }
  }

  &.loving {
    .loyalty-system-status-item-name {
      color: #E33F3F;
    }

    .loyalty-system-status-item-about {
      display: block;
      background: #441D1D;
    }
  }

  .loyalty-system-status-item-about {
    display: block;
  }
}

.chat-page-models-list {
  padding: 0 24px;
}


.chat-voice-panel {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 92px;
  width: 92px;
  height: 48px;
  background-color: #2A2A2A;
  transition: var(--transition);
  border-radius: 24px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #383838;
    .item-voice path {
      fill: #446CFC;
    }
  }
  &.active {
    background-color: #446CFC;
    .item-voice path {
      fill: #446CFC;
    }
    .item-text path {
      stroke: #fff;
    }
  }
}

.chat-voice-panel-item {
  width: 44px;
  aspect-ratio: 1;
  min-width: 44px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  path {
    transition: var(--transition);
  }
}

.chat-voice-panel-active {
  transition: var(--transition);
  pointer-events: none;
  width: 44px; aspect-ratio: 1;
  min-width: 44px;
  position: absolute;
  left: 2px;
  background-color: #fff;
  border-radius: 50%;
}

.chat-voice-panel.active {
  .chat-voice-panel-active {
    transform: translateX(44px);
  }
}



.chat-container.voice-chat {
  .chat-control:before {
    opacity: 1;
  }
  .chat-input {
    .form-control {
      background-color: #273871;
    }
    .form-control-bg {
      background: #446CFC;
      &:before {
        background: #446CFC;
      }
    }
    .btn {
      //font-size: 16px;
      background: #446CFC;
      &:before {
        background: #446CFC;
      }
      &:after {
        display: none;
      }
    }
  }
  .chat-input-message-type {
    color: #446CFC;
  }
}

.chat-input-message-type {
  transition: var(--transition);
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  left: 0;
  right: 0;
  font-family: var(--font-semibold);
}

.chat-control.message-type-visible {
  padding-top: 40px;
  .chat-input-message-type {
    transform: translateY(-40px);
  }
}

.chat-board-model-arrow {
  display: none;
  margin-left: 4px;
  svg {
    width: 20px;
    height: 20px;
  }
}

