@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
.categories-page {
  padding-bottom: 8px;
}
}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}