.premium-page {
  padding-bottom: 24px;
}
.premium-container {
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  background-color: var(--bg-secondary);
  padding: 32px 24px 24px;
  margin: 0 auto;
  max-width: 748px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 216px;
    height: 140px;
    top: -30px;
    background: rgba(255, 255, 255, 0.16);
    filter: blur(30px);
    border-radius: 50%;
    pointer-events: none;
    z-index: 1;
  }

  h6 {
    margin-bottom: 24px;

    .border-gradient-secondary {
      transform: rotate(10deg);
      height: 44px;
      width: auto;
      padding: 0 12px;

      &:before, &:after {
        border-radius: 40px;
      }
    }
  }

}

.premium-benefits {
  padding: 24px;
  border-radius: 24px;
  background: rgba(18, 18, 18, 0.4);
  width: 100%;

}

.premium-benefits-check {
  margin-right: 13px;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: var(--secondary-color);
  position: relative;

  .react-icon {
    position: absolute;
    top: -2px;
    right: -2px;
  }
}

.premium-plan {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
}


.premium-plan-item {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 24px;
  cursor: pointer;
  padding: 0;

  &:hover {
    &:before, &:after {
      opacity: 1;
    }
  }

  &:before, &:after {
    opacity: 0;
    border-radius: 24px;
  }
  .form-radio {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
}
.premium-plan-item.active {
  &:before {
    opacity: 1;
  }

  &:after {
    opacity: 0;
  }

  .premium-plan-item-off {
    &:after {
      background-color: #fff;
    }
  }
  .credit-plan-bonus {
    border: 1px solid #fff;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background: #fff;
    z-index: 3;
    span {
      background: #fff;
      -webkit-text-fill-color: var(--bg-main);
    }
    &:before, &:after {
      opacity: 0;
    }
  }

}

.premium-plan-item-bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 24px;
  z-index: 1;

  &:before {
    content: '';
    width: 115px;
    height: 88px;
    position: absolute;
    top: -30px;
    background-color: #fff;
    opacity: 0.5;
    filter: blur(30px);
  }

  &:after {
    content: '';
    width: 120px;
    height: 88px;
    position: absolute;
    bottom: -30px;
    background: var(--gradient);
    filter: blur(30px);
  }
}

.premium-plan-item-content {
  padding-bottom: 24px;
  position: relative;
  z-index: 1;
  width: 100%;
  &.credit-plan {
    padding-bottom: 12px;
  }
}

.premium-plan-item-diamond {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url("../../../../assets/icons/diamond.svg") no-repeat center center/ 24px;
  border: 1px solid var(--border-color);
}

.premium-plan-item-recommended {
  flex: 1;
  background: var(--gradient);
  padding-left: 12px;
  display: flex;
  align-items: center;
  font-size: 10px;
  border-radius: 24px;
  margin-right: 2px;
  padding-right: 30px;
}

.premium-plan-item-off {
  border-radius: 24px;
  height: 24px;
  margin-top: 24px;
  padding: 0 12px;
  font-size: 14px;

  &:before, &:after {
    border-radius: 24px;
  }
}

.premium-plan-item-price {
  font-family: var(--font-montserrat-bold);
  font-size: 32px;
  line-height: 24px;
  margin-top: 16px;
}

.premium-footer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 44px;
  gap: 24px;

  .btn {
    flex: 1;
  }
}

.credit-plan-coins {
  text-align: center;
  font-family: var(--font-bold);
  font-size: 32px;
  line-height: 48px;
  margin-top: 52px;
  margin-bottom: 24px;
  padding-right: 8px;
}
.credit-plan-bonus {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 14px;
  border: 1px solid transparent;
  &:before {
    z-index: 1;
    border-radius: 18px;
  }
  &:after {
    z-index: 1;
    border-radius: 18px;
  }
  span  {
    z-index: 2;
  }
}
.credit-plan-price-container {
  padding: 0 12px;
}
.credit-plan-price {
  background: rgba(18, 18, 18, 0.2);
  height: 40px;
  width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  backdrop-filter: blur(16px);
}

.payment-method-item {
  min-height: 120px;
  padding: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 24px;
  background: #fff;
  color: var(--bg-main);
  cursor: pointer;
  height: 100%;

  &:hover {
    &:before, &:after {
      opacity: 1;

    }
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    bottom: -2px;
    right: -2px;
    opacity: 0;
    pointer-events: none;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    transition: var(--transition);
    background: var(--gradient);
  }

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    opacity: 0;
    pointer-events: none;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    filter: blur(20px);
    transition: var(--transition);
    background: var(--gradient);
  }
}

.payment-method-item-logo {
  position: relative;
  z-index: 1;
  min-height: 56px;
  display: flex;
  align-items: center;
  max-width: 100%;
  img {
    max-width: 100%;
    height: 100%;
    max-height: 40px;
    object-fit: cover;
  }
}

.payment-method-item-stub {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background: url("../../../../assets/icons/wallet.svg") no-repeat center center, rgba(18, 18, 18, 0.1);
}

.payment-method-page {
  .horizontal-scroll {
    .form-group {
      width: 140px;
      min-width: 140px;
      align-items: center;
      justify-content: center;
    }
    .form-control-wrap {

      background-color: transparent;

      .form-control-bg {
        display: none;
      }
    }

    .form-control {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: var(--transition);
      background-color: rgba(255, 255, 255, 0.16);

      &:hover {
        background-color: rgba(255, 255, 255, 0.24);
      }
    }
  }
}


.billing-type {
  background: rgba(18, 18, 18, 0.4);
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 32px;
  margin-top: 24px;
  position: relative;
  overflow: hidden;

  &.subscription {
    padding-right: 16px;
    .billing-type-bg:before {
      background: var(--gradient);
    }
  }

  &.credits {
    .billing-type-bg:before {
      background: #4AA5F3;
    }
  }
}

.billing-type-bg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: 16px;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    filter: blur(60px);
  }

  .react-icon, img {
    position: relative;
    align-self: flex-end;
  }
  img {
    width: 112px;
  }
}

.payment-platform-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  margin-top: 24px;
  position: relative; z-index: 1;

}
.payment-platform-icons-item {
  position: relative;
  display: flex;
  justify-content: center;
  &:hover .payment-platform-icons-item-text{
    opacity: 1;
  }
}
.payment-platform-icons-item-text {
  pointer-events: none;
  position: absolute;
  transition: var(--transition);
  opacity: 0;
  bottom: calc(100% + 8px);
  padding: 8px;
  background: rgba(18, 18, 18, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(16px);
  border-radius: 16px;
  width: 200px;
  font-size: 12px;

}

.premium-btn-card-info {
  background: rgba(255, 255, 255, 0.2);
  height: 28px;
  display: flex;
  align-items: center;
  padding: 4px;
  padding-right: 16px;
  margin-left: 8px;
  border-radius: 16px;
  font-size: 14px;
  .list-pay-card-image {
    margin-left: 4px;
    margin-right: 8px;
  }
}

.premium-transaction {
  margin-top: 40px;
  width: 100%;
  height: 64px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  &.success {
    background: rgba(29, 226, 61, 0.08);

  }
  &.failed {
    background: rgba(227, 63, 113, 0.08);
  }
  &.pending {
    justify-content: space-between;
  }
}