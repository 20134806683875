.avatar {
  width: 56px;
  height: 56px;
  min-width: 56px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    right: 1px;
    border-radius: 50%;
    background-color: #fff;

  }
  &.xl {
    width: 102px;
    height: 102px;
    min-width: 102px;

    &.user svg {
      width: 60px;
      height: 60px;
      //background: url("../icons/user.svg") no-repeat center center / 60px, #fff;
    }
  }

  &.lg {
    width: 64px;
    height: 64px;
    min-width: 64px;

    &.user svg {
      width: 40px;
      height: 40px;
      //background: url("../icons/user.svg") no-repeat center center / 40px, #fff;
    }
  }

  &.sm {
    width: 48px;
    height: 48px;
    min-width: 48px;

    &.user svg {
      width: 28px;
      height: 28px;
      //background: url("../icons/user.svg") no-repeat center center / 28px, #fff;
    }
  }

  &.xs {
    width: 40px;
    height: 40px;
    min-width: 40px;

    &.user svg {
      width: 24px;
      height: 24px;
      //background: url("../icons/user.svg") no-repeat center center / 24px, #fff;
    }
  }

  &.xxs {
    width: 32px;
    height: 32px;
    min-width: 32px;

    &.user svg {
      width: 20px;
      height: 20px;
      //background: url("../icons/user.svg") no-repeat center center / 20px, #fff;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    position: relative;
  }
  .react-icon {
    position: relative;
  }

}