.categories-page {
  padding: 0 24px 24px;
  .home-models {
    margin: 0;
    h2 {
      display: none;
    }
  }
  .model-style {
    margin-top: 0px;
  }
}