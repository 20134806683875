.payment-item {
  padding: 32px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  border-radius: 32px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 216px;
    height: 140px;
    background: rgba(255, 255, 255, 0.16);
    z-index: -1;
    top: -40px;
    border-radius: 40%;
    filter: blur(40px);
  }
  &.success {
    background: rgba(29, 226, 61, 0.08);
  }
  &.pending {
    background: rgba(255,255,255,0.08);
  }
  &.failed {
    background: rgba(227, 63, 113, 0.08);

  }
}