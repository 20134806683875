@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .form-control {
    &::placeholder {
      font-size: 14px !important;
    }
  }
  .input-icon {
    left: 16px;
    width: 24px;
    height: 24px;
  }

}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}