@media only screen and (max-width: 1199px) {
  .home-models-list {
    padding-bottom: 0;

    .active-element-container {
      transform: none !important;
      width: 33%;
      min-width: 33%;
    }
  }
  .home-page {
    padding-top: 64px;
  }
}

@media only screen and (max-width: 991px) {
  .home-top-text {
    margin-top: 16px;
    font-size: 14px;
    line-height: 20px;
    max-width: 440px;
  }
  .home-page {
    padding-top: 32px;
  }
  .home-top-container {
    flex-direction: column;
  }
  .home-slider {
    display: block;

  }
  .top-carousel-item {
    transform: none !important;
    position: relative !important;
    background: var(--bg-main);
    padding: 12px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 240px;

    .message-container {
      margin-top: 0;
    }

    .message {
      background: rgba(255, 255, 255, 0.04) !important;
    }

    .chat-board-model {
      display: inline-flex;
      position: relative;
      top: 0;
      margin-bottom: 56px;
    }
  }
  .top-carousel, .top-carousel-item-avatar, .top-carousel-item-about {
    display: none;
  }
  .top-carousel-item-chat {
    display: flex !important;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
  }
  .top-carousel-item-chat-board {
    margin-left: 44px;
  }
  .top-carousel-item-message-name {
    display: block;
  }

  .home-models-list .active-element-container {
    width: 50%;
    min-width: 50%;
  }
  .home-models {
    margin-top: 60px;
  }
  .home-craft {
    padding-top: 86px;
    padding-bottom: 32px;
  }
  .home-craft .btn {
    margin-top: 16px;
  }
  .home-craft-bg {
    img {
      width: 800px;
    }
  }
  .home-faq {
    width: 100%;
  }
  .home-description {
    width: 100%;
    margin-top: 32px;
  }
  .home-faq-item-head {
    padding: 16px;
  }
  .home-faq-item.active .home-faq-item-content {
    padding: 0 16px 16px;
  }
  .home-description-container {
    padding: 16px;
    border-radius: 24px;
  }
  .home-footer {
    padding: 24px;

    .container {
      padding: 0 !important;
    }
  }
  .home-footer-section .container {
    margin: 0 !important;
    max-width: 100%;
    padding: 0;

  }
  .home-footer-nav {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .home-tags {
    margin-bottom: 0;
    padding-bottom: 8px;
  }
  .home-page {
    padding-top: 0px;
  }
  .home-description {
    padding: 0;
  }
  .home-models {
    margin-top: 32px;
  }
  .home-footer-section {
    padding: 0;
  }
  .home-footer {
    padding: 16px;
    border-radius: 32px 32px 0 0;
  }
  .home-top-container {
    padding: 16px 0;
  }
  .home-top-section {
    border-radius: 44px;
  }
  .home-models-list {
    margin: -8px;
    margin-top: 8px;
  }
  .home-models-list .active-element-container {
    padding: 8px;
  }
  .model-style {
    margin-top: 16px;

    &.horizontal-scroll-container {
      &:before, &:after {
        display: none !important;
      }
    }

    .horizontal-scroll {
      padding: 0 16px !important;
    }
  }
  .model-style .horizontal-scroll {
    margin: 0 -16px;
  }
  .home-top-footer {
    display: none;
  }
  .home-footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 8px;

    .home-footer-contacts, {
      display: none;
    }
  }
  .home-footer-contacts.bottom {
    display: block;
  }
  .home-footer-nav {
    margin-top: 18px;
    padding: 0 8px;
  }

  .home-footer-nav .col-4 {
    width: calc(33.3333% - 16px);
    background: rgba(255, 255, 255, 0.04);
    border-radius: 16px;
    padding: 12px;
    margin: 8px;
  }
  .home-footer-bottom {
    margin-top: 16px;
  }
  .home-page h1 {
    width: 100%;
    margin: 32px auto 0;
    text-align: center;
    font-size: 32px;
    line-height: 40px;
  }
  .home-top-left .btn, .home-craft .btn {
    width: 100%;

    .react-icon {
      position: absolute;
      left: 20px;
      margin: 0;

    }
  }
  .home-top-left .btn {
    margin-top: 32px;
  }
  .home-top-gender {
    width: 100%;
  }
  .home-slider {
    margin-left: -16px;
    margin-right: -16px;

  }
  .home-slider .slick-slide > div {
    width: 100%;
    padding: 0 16px;
  }
  .top-carousel-item {
    width: 100%;
  }
  .model-style {
    .horizontal-scroll {
      padding-left: 12px;
      padding-right: 48px;
      justify-content: flex-start;
    }
  }
}

@media only screen and (max-width: 575px) {

  .home-craft-bg {
    top: 0px;
  }
  .home-footer-nav {
    display: grid;
    margin: 0;
    padding: 0;
    gap: 16px;
    margin-top: 30px;
  }
  .home-footer-nav .col-4 {
    width: auto;
    margin: 0;

    &:first-child {
      grid-column: 1 / 6;
      grid-row-start: 1;
      grid-row-end: 8;
    }

    &:nth-child(2) {
      grid-column: 6 / 12;
      grid-row-start: 1;
      grid-row-end: 6;
    }

    &:last-child {
      grid-column: 6 / 12;
      grid-row-start: 6;
      grid-row-end: 12;
    }
  }


  .home-models-list .model-card {
    height: auto;
  }
  .home-footer-bottom {
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;

    & > .d-flex {
      margin-bottom: 24px;
    }
  }
  .home-footer-payment {
    flex: 1;
    margin: 0;

    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }
  .home-page .section-bg {
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    border-radius: 32px;
    //overflow: hidden;
  }
  .home-slider {
    margin-left: -16px;
    margin-right: -16px;

    .slick-slide {
      padding: 0 16px;
    }
  }
  .home-top-text {
    display: none;
  }
  .home-models .model-card .model-card-category {
    padding: 0 6px;
  }
}

@media only screen and (max-width: 374px) {
  .home-models-list .active-element-container {
    width: 100%;
    min-width: 100%;
    height: auto;
  }
  .home-models .model-card .model-card-category {
    padding: 0 12px;
  }
}

/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}