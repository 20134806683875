.model-profile-page {
  .slick-arrow {
    display: none !important;
  }

  .model-profile-slide img {
    border-radius: 0 0 24px 24px;
    width: 500px;
    max-width: 100%;
    object-position: top;
  }

  .btn-expand {
    display: none;
  }
}

.model-profile {
  overflow: hidden;
}

.model-profile-gallery {
  //padding: 24px 0 24px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 0 12px;
    background-color: #303030;
    border-radius: 24px;
  }
}

.modal-container .model-profile-gallery, .model-profile-page .model-profile-gallery {
  aspect-ratio: auto;
}

.model-profile-section {
  width: 356px;
  margin-right: -12px;
}

.model-profile-slider-small {
  padding: 12px 20px;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;

  .slick-list {
    border-radius: 12px;
  }

  //&:hover {
  //  &:before, &:after {
  //    //opacity: 1;
  //  }
  //}
  //
  //&:before {
  //  opacity: 0;
  //  pointer-events: none;
  //  transition: var(--transition);
  //  z-index: 1;
  //  content: '';
  //  position: absolute;
  //  left: 20px;
  //  top: -1px;
  //  bottom: -1px;
  //  width: 56px;
  //  background: linear-gradient(to right, #303030 10%, transparent 100%);
  //}
  //
  //&:after {
  //  opacity: 0;
  //  pointer-events: none;
  //  transition: var(--transition);
  //  z-index: 1;
  //  content: '';
  //  position: absolute;
  //  right: 0;
  //  top: 0;
  //  bottom: 0;
  //  width: 56px;
  //  background: linear-gradient(to left, #303030 10%, transparent 100%);
  //}

  .slick-current .model-profile-slide {
    border-color: var(--tint-color);
    opacity: 1;

    &:before {
      opacity: 1;
    }
  }
}

.model-profile-slide {
  position: relative;
  max-height: 412px;
  height: 412px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  width: 100%;

  &.small {
    transition: var(--transition);
    aspect-ratio: 1/1.1;
    height: auto;
    padding: 0 4px;
    border-radius: 12px;
    opacity: 0.4;
    position: relative;
    max-height: 60px;

    &:before {
      pointer-events: none;
      transition: var(--transition);
      opacity: 0;
      content: '';
      position: absolute;
      inset: 0 4px;
      background: var(--gradient);
      border: 2px solid transparent;
      background: var(--gradient) border-box;
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      border-radius: 12px;
    }

    img {
      max-width: 100%;
      border-radius: 12px;
    }

    & + .blur-btn {
      display: none !important;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
  }

}


.model-profile-info {
  padding: 24px;
}

.model-profile-cards {
  margin: 8px -8px -8px;
  display: flex;
  flex-wrap: wrap;
}

.model-profile-card {
  margin: 8px;
  width: calc(50% - 16px);
  background: rgba(255, 255, 255, 0.05);
  padding: 12px 16px;
  border-radius: 16px;

}


.model-profile-head {
  align-items: center;
  display: none;
  position: absolute;
  z-index: 100;
  padding: 24px;
  justify-content: space-between;
  width: 100%;

  .btn, .chat-settings-btn {
    backdrop-filter: blur(20px);
  }

  .btn .react-icon {
    margin: 0 !important;
  }

  .chat-top-controls {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
  }

  .chat-settings {
    background: transparent;
  }

  .chat-settings-btn {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.16);

    &:hover {
      background: rgba(255, 255, 255, 0.24);

    }
  }
}

.model-profile-head .chat-top-controls {
  height: 40px;
}

.model-profile .btn-expand {
  left: 24px;
}

body:has(.model-profile-page) main {
  padding-top: 0;
}

.modal-profile {
  padding: 0 !important;
  max-height: 90dvh;
  overflow: hidden;
  max-width: 90vw;
  display: flex;

  .model-profile-slide-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;
    border-radius: 8px;
    padding-bottom: 92px;
  }

  .model-profile-slide {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    display: block !important;
    padding: 0;
    img {
      max-width: 100%;
      border-radius: 8px;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }

  }

  .model-profile-bg-img {
    display: block;
  }

  .model-profile {
    display: flex;
    gap: 56px;
    flex: 1;
    overflow: hidden;
  }

  .model-profile-gallery {
    flex: 1;
    width: 50%;
    min-width: 50%;
    padding: 24px;

    &:before {
      display: none;
    }

    .btn-expand-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .model-profile-slider-main {
      padding: 0;
      flex: 1;
      border-radius: 8px;

      .slick-list, .slick-track {
        height: 100%;
        border-radius: 8px;
      }

      .slick-slide {
        display: flex;

        & > div {
          flex: 1;
          display: flex;

          & > div {
            flex: 1;
            display: flex;
            position: relative;
          }
        }
      }

      .model-profile-slide {
        position: absolute;
        inset: 0;
        padding: 0;
        height: 100%;
        max-height: 100%;

        img {
          height: 100%;
          width: 100%;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border-radius: 8px;
        }
      }
    }
  }

  .model-profile-slider-small {
    margin-bottom: 0;
    margin-top: 16px;
    padding: 0;

    .slick-list {
      &:before, &:after {
        display: none !important;

      }
    }

  }

  .model-profile-info {
    height: 100%;
    overflow: auto;
    flex: 1;
    width: 50%;
    padding-right: 56px !important;
    padding-top: 72px !important;
    padding-bottom: 24px !important;
  }
}

.model-profile-bg-img {
  position: absolute;
  inset: 0;
  overflow: hidden;
  display: none;

  img {
    filter: blur(40px);
    width: 100%;
    height: 100%;
  }
}

.model-profile-page .model-profile-gallery {
  margin: 0 auto;

  &:before {
    display: none;
  }

}

.model-profile-page .model-profile-slider-small .slick-list {
  &:before {
    background: linear-gradient(to right, var(--bg-main) 10%, transparent 100%) !important;
  }

  &:after {
    background: linear-gradient(to left, var(--bg-main) 10%, transparent 100%) !important;
  }
}


.model-profile-head .chat-top-controls {
  .chat-settings-btn {
    width: 40px;
    height: 40px;
  }

  .chat-voice-panel {
    height: 40px;
    min-width: 76px;
    width: 76px;
  }

  .chat-voice-panel-active, .chat-voice-panel-item {
    width: 36px;
    min-width: 36px;
  }

  .chat-voice-panel.active .chat-voice-panel-active {
    transform: translateX(36px);
  }
}