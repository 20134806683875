.shared-chats-page {
  padding: 0 24px 24px;
  //overflow: hidden;
  min-height: calc(100dvh - 128px);
  .pagination-wrap {
    position: relative;
    z-index: 1;
    margin-top: 24px;
    padding-bottom: 0 !important;
  }
  .horizontal-scroll-container {
    margin-bottom: 32px;
    z-index: 0;
  }
  .home-craft {
    .btn {
      margin-top: 32px!important;
    }
    h2 {
      font-size: 32px;
      line-height: 40px;
    }
  }

}

.shared-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  height: 100%;

  .btn-expand-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 2;
    margin-left: 24px;

    .btn-expand {
      left: auto;
      top: auto;
    }
  }

  .model-card-category {
    top: 16px;
    left: auto;
    right: 16px;
    pointer-events: none;
  }

  .btn {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      border-radius: 12px;
      transition: var(--transition);
      opacity: 0;
      background: var(--gradient);
      pointer-events: none;
    }

    .btn-content {
      z-index: 1;
    }

    .btn-icon-absolute {
      left: 12px !important;
    }
  }
}

.shared-list-item:hover {
  .shared-list-item-body-container {
    &:before, &:after {
      opacity: 1;
    }
  }

  .btn:after {
    opacity: 1;
  }
}

.shared-list-item-body-container {
  height: 100%;
  width: 100%;
  border-radius: 32px;
  margin-top: -51px;
  position: relative;
  background: var(--bg-secondary);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--gradient);
    border-radius: 40px;
    filter: blur(80px);
    opacity: 0;
    pointer-events: none;
    transition: var(--transition);
  }

  &:before {
    content: '';
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border-radius: 34px;
    background: var(--gradient);
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: var(--transition);
  }
}

.shared-list-item-body {
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  border-radius: 32px;
  padding: 68px 24px 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

}