@media only screen and (max-width: 1199px) {
  main {
    padding-left: 0 !important;
    padding-top: 90px;
  }
  .page-title {
    display: none;
  }
  body:has(aside.visible) {
    overflow: hidden;
  }
}

@media only screen and (max-width: 991px) {
  body:has(.chat-page) {
    overflow: hidden;
    height: 100dvh;
  }
  section {
    margin-top: 60px;
  }
  .tab {
    height: 56px;
  }
  .pagination-wrap {
    padding-bottom: 16px !important;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
  }
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-expand {
    left: 8px;
    top: 8px;
  }
  section {
    margin-top: 32px;
  }
  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  main {
    padding-top: 76px;
  }
  .app-wrapper.tg-app {
    header {
      height: 164px;
      padding-top: 108px!important;
      padding-bottom: 16px!important;
    }
  }
}

@media only screen and (max-width: 575px) {

  .tabs {
    width: 100%;
  }
  .tab {
    flex: 1;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
  }
  .name-age {
    font-size: 20px;
  }
}


/* touchscreens */
@media (hover: none) {
  a, .link, .text-active {
    text-decoration: underline;
  }
  //.horizontal-scroll-arrow {
  //  &.arrow-left {
  //    left: -12px!important;
  //  }
  //  &.arrow-right {
  //    right: -12px!important;
  //  }
  //
  //  //display: none!important;
  //}
  .horizontal-scroll {
    overflow-x: auto !important;
  }
}

/* mouse */
@media (hover: hover) {
  /* ... */


}
