
::-webkit-scrollbar {
  width: 6px;
  height: 2px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #676767;
}

::-webkit-scrollbar-track {
}

::selection {
  color: #fff;
  -webkit-text-fill-color: #fff;
  background-color: var(--secondary-color);
}

* {
  //scrollbar-color: #676767 transparent;
  //scrollbar-width: thin;
  outline: none;
}

html {
  scroll-behavior: smooth;
  //overflow-x: hidden;
}


body {
  //font-size: clamp(14px, 2vw, 16px);
  //line-height: clamp(20px, 2vw, 24px);
  overflow-x: hidden;
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 24px;
  //min-height:var(--viewport-height) ;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-main);
  color: var(--text-color);
  cursor: default;
}


h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 0;
}

p {
  font-size: 14px;
  line-height: 20px;
  user-select: auto;
}

h1 {
  position: relative;
  font-family: var(--font-black);
  text-wrap: balance;
  font-size: 48px;
  line-height: 58px;
}

h2 {
  font-family: var(--font-black);
  font-size: 40px;
  line-height: 48px;
}

h3 {
  font-family: var(--font-black);
  font-size: 32px;
  line-height: 40px;
}

h4 {
  font-family: var(--font-black);
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-family: var(--font-black);
  font-size: 16px;
  line-height: 24px;
}

h6 {
  font-family: var(--font-bold);
  font-size: 24px;
  line-height: 36px;
}


#root, main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.app-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.app-wrapper.tg-app {
  header {
    height: 156px;
    padding-top: 68px!important;
    padding-bottom: 24px!important;
  }
  main {
    padding-top: 156px;
  }

  .desktop {
    header {
      height: 124px;
      padding-top: 44px;
      padding-bottom: 16px;

    }
    main {
      padding-top: 124px;
    }
  }
}

main {
  //transition: var(--transition);
  padding-top: 128px;
  padding-left: 136px;
  overflow: hidden;
}

body:has(.aside.big-aside) main, body:has(.aside.big-aside) header {
  padding-left: 330px;
}


section {
  position: relative;
  margin-top: 80px;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}

.text-muted {
  color: var(--muted-color) !important;
}

.text-muted-14 {
  color: var(--muted-color) !important;
  font-size: 14px;
  line-height: 20px;
}

.text-danger {
  color: var(--danger) !important;
}
.text-error {
  color: var(--error) !important;
}

.text-danger-14 {
  color: var(--danger);
  font-size: 14px;
  line-height: 20px;
}

.text-dark {
  color: rgba(255, 255, 255, 0.64) !important;
}

.text-dark-14 {
  color: rgba(255, 255, 255, 0.64);
  font-size: 14px;
  line-height: 20px;
}

.text-bold {
  font-family: var(--font-bold);
}

.text-bold-14 {
  font-family: var(--font-bold);
  font-size: 14px;
  line-height: 20px;
}
.text-bold-12 {
  font-family: var(--font-bold);
  font-size: 12px;
  line-height: 18px;
}

.text-semibold {
  font-family: var(--font-semibold);
}

.text-semibold-14 {
  font-family: var(--font-semibold);
  font-size: 14px;
  line-height: 20px;
}

.text-14 {
  font-size: 14px;
  line-height: 20px;
}
.text-12 {
  font-size: 12px;
  line-height: 18px;
}

a, .link, .text-active {
  cursor: pointer;
  color: #fff;
  transition: var(--transition);
  text-decoration: none;

  &:hover {
    background: -webkit-linear-gradient(left, var(--tint-color), var(--secondary-color));
    background: -o-linear-gradient(right, var(--tint-color), var(--secondary-color));
    background: -moz-linear-gradient(right, var(--tint-color), var(--secondary-color));
    background: linear-gradient(to right, var(--tint-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.text-success {
  color: var(--success) !important;
}

.text-tint {
  background: -webkit-linear-gradient(left, var(--tint-color), var(--secondary-color));
  background: -o-linear-gradient(right, var(--tint-color), var(--secondary-color));
  background: -moz-linear-gradient(right, var(--tint-color), var(--secondary-color));
  background: linear-gradient(to right, var(--tint-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-tint-active {
  background: -webkit-linear-gradient(left, var(--tint-color), var(--secondary-color));
  background: -o-linear-gradient(right, var(--tint-color), var(--secondary-color));
  background: -moz-linear-gradient(right, var(--tint-color), var(--secondary-color));
  background: linear-gradient(to right, var(--tint-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    background: var(--gradient);
    transition: var(--transition);
  }

  &:hover:after {
    opacity: 1;
  }
}

.pointer-event {
  pointer-events: none;
}
.react-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.section-bg {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(227, 63, 113, 0.32);
    border-radius: 360px;
    filter: blur(100px);
    z-index: -1;
    pointer-events: none;
  }
}


.bg-16 {
  background: rgba(255, 255, 255, 0.16);
}

.bdrs-12 {
  border-radius: 12px!important;
}

.bdrs-24 {
  border-radius: 24px!important;
}

.bdrs-32 {
  border-radius: 32px!important;
}

.name-age {
  span {
    font-family: var(--font-montserrat-regular);
  }
}


.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.page-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  .react-icon {
    margin-right: 8px;
  }
}

.card {
  background: rgba(255, 255, 255, 0.08);
  color: var(--text-color);
}

.card-dark {
  background: rgba(18, 18, 18, 0.4);
  border-radius: 32px;
  color: var(--text-color);
}


.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step-container {
  display: flex;
  align-items: center;
}

.step {
  width: 24px;
  min-width: 24px;
  height: 24px;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  border-radius: 48px;
  background: rgba(255, 255, 255, 0.08);
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: var(--font-bold);
  transition: var(--transition);
  color: rgba(255, 255, 255, 0.2);

  &.current {
    color: #fff;
    box-shadow: inset 0 0 0 2px #fff;
  }

  &.complete {
    background: url("../../assets/icons/check.svg") no-repeat center center, var(--gradient);

    span {
      display: none;
    }
  }
}

.step-separator {
  position: relative;
  flex: 1;
  background: rgba(255, 255, 255, 0.08);
  height: 2px;
  width: 100%;
  margin-left: 2px;
  margin-right: 2px;
  overflow: hidden;
  border-radius: 4px;

  &.current:after {
    transform: translateX(-35%);
  }

  &.complete:after {
    transform: translateX(0);
    background: var(--gradient);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    transition: var(--transition);
    background: #fff;
    transform: translateX(calc(-100% - 1px));
  }
}


.form-radio-custom {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: var(--bg-main);
  border-radius: 50%;
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    border: none;
    background: var(--gradient);

    &:after {
      opacity: 1;
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 20px;
    opacity: 0;
    pointer-events: none;
    transition: var(--transition);
    background: #fff;
  }
}

.letter-uppercase::first-letter {
  text-transform: uppercase;
}

.slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 4px;
}

.slick-dots li {
  width: auto;
  height: auto;
  margin: 0 4px;
}

.slick-dots li button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.slick-dots li button:before {
  content: '';
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.16);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #fff;
}

.slick-next {
  right: 24px;
  background: rgba(255, 255, 255, 0.16);
  width: 40px;
  height: 40px;
  border-radius: 80px;
  transition: var(--transition);
  cursor: pointer;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 1;

  &:hover {
    background: rgba(255, 255, 255, 0.24);
  }

  &:before {
    content: '';
    opacity: 1;
    pointer-events: none;
    background: url("../icons/chevron_right.svg") no-repeat center center;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.slick-arrow.slick-disabled {
  //pointer-events: none;
}
.slider-container {
  &:hover {
    .slick-arrow {
      opacity: 1;
      &.slick-disabled {
        opacity: 0.8;
        background: rgba(255, 255, 255, 0.04);
      }
    }
  }
  .slick-arrow {
    opacity: 0;
  }
}
.slick-prev {
  left: 24px;
  background: rgba(255, 255, 255, 0.16);
  width: 40px;
  height: 40px;
  border-radius: 80px;
  transition: var(--transition);
  cursor: pointer;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 1;
  z-index: 1;
  &:hover {
    background: rgba(255, 255, 255, 0.24);
  }

  &:before {
    content: '';
    opacity: 1;
    pointer-events: none;
    background: url("../icons/chevron_left.svg") no-repeat center center;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

hr {
  opacity: 0.1;
  margin: 24px 0;
}


.Toastify__toast {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  background: var(--bg-main);
  padding: 16px;
  font-family: var(--font-regular);
  .react-icon svg {
    width: 20px !important;
    height: 20px!important;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: rgba(255, 255, 255, 0.1);
  }
}

.Toastify__progress-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  height: 4px;
  background: var(--gradient);
}

.Toastify__toast--error {
  background: #562131;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.popover {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
}

.popover.visible {
  .popover-panel-container {
    display: block;
  }
}

.popover-panel-container {
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 4px;
  display: none;
}

.popover-panel {
  background: rgba(18, 18, 18, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(16px);
}

.gender-page {
  position: fixed;
  top: 12px;
  z-index: 300;
}

.border-gradient-secondary {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  &:hover, &.active {
    &:before {
      transition: var(--transition);
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: -1px;
    bottom: -1px;
    top: -1px;
    right: -1px;
    background: var(--gradient);
    border-radius: 24px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--bg-secondary);
    border-radius: 24px;
  }
  span {
    position: relative;
    z-index: 1;
  }
}

b {
  font-family: var(--font-bold);
}

.btn-expand-container {
  position: relative;

  &:hover .btn-expand {
    opacity: 1;
  }
}

.btn-expand {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 12px;
  left: 12px;
  background: rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transition: var(--transition);
}

.btn-trash-container {
  position: relative;

  &:hover .btn-trash {
    opacity: 1;
  }
}
.btn-trash {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 12px;
  right: 12px;
  background: rgba(227, 63, 113, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 0;
  z-index: 1;
  transition: var(--transition);
  cursor: pointer;
  &:hover {
    background: rgba(227, 63, 113, 0.32);
  }
  &:active {
    background: rgba(227, 63, 113, 0.08);
  }
}


.btn-edit {
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 64px;
  -moz-border-radius: 64px;
  border-radius: 64px;
  width: 32px;
  height: 32px;
  background: url("../icons/edit_active.svg") no-repeat center center, #fff;
}

.dot-separator {
  width: 4px;
  height: 4px;
  min-width: 4px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #fff;
  margin: 0 8px;
}
.pre-wrap {
  white-space: pre-wrap;
}

.tabs {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 32px;
  padding: 4px;
  display: inline-flex;
  font-family: var(--font-bold);
}

.tab {
  border-radius: 32px;
  padding: 12px 32px;
  display: grid;
  align-items: center;
  cursor: pointer;
  color: var(--muted-color);
  transition: var(--transition);
  flex: 1;
  justify-content: center;
  max-height: 100%;
  &:hover {
    color: #fff;

  }

  &.active {
    background: #fff;
    color: var(--bg-main);
  }
}
.tabs.sm {
  height: 40px;
  padding: 0;
  font-size: 14px;
 .tab {

   padding: 8px 16px;
 }
}
.empty-list {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--dark-color);
  text-align: center;
  padding-bottom: 100px;
}
.empty-list-content {
  background: url("../images/logo_dark.png") no-repeat center top/ 62px;
  padding-top: 70px;
  min-width: 200px;
}
.preload-hidden-images {
  position: absolute;
  opacity: 0;
  display: none;
  img {
    width: 10px;
    height: 10px;
  }
}
.preload-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
  & + img {
    position: relative;
  }
}

.blur-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blur-content img  {
  filter: blur(10px);
  pointer-events: none;
}
.btn.blur-btn {
  position: absolute;
  z-index: 1;
  .btn-content {
    //padding-left: 12px!important;
  }
  .btn-icon-left {
    margin-right: 4px!important;
  }
  svg {
    width: 20px!important;
    height: 20px!important;
  }
}


@keyframes animVisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



.play-btn {
  position: relative;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.16);
  &:hover {
    background: rgba(255, 255, 255, 0.24);
  }

  &:before {
    pointer-events: none;
    opacity: 0;
    content: '';
    position: absolute;
    width: 40px;
    height: 14px;
    border-radius: 20px;
    transform: rotate(-45deg);
    background: var(--secondary-color);
    filter: blur(6px);
    animation: my-ai-process-bg-rotate 8s infinite;
    z-index: -1;
  }

  &:after {
    pointer-events: none;
    opacity: 0;
    content: '';
    position: absolute;
    width: 40px;
    height: 14px;
    border-radius: 20px;
    transform: rotate(45deg);
    background: var(--tint-color);
    filter: blur(6px);
    animation: my-ai-process-bg-rotate-2 10s infinite;
    z-index: -1;
  }
  &.active {
    &:before, &:after {
      opacity: 0.64;
    }
  }
}



.new-model-item {
  .model-card-category {
    animation: 2s animVisible 3s both;
  }

  .name-age {
    opacity: 0;
    animation: 4s newModelTitle 1s both;
  }

  .model-about {
    opacity: 0;
    animation: 2s newModelAbout 3s both;
  }
}
@keyframes newModelTitle {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes newModelAbout {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.new-model-gradient-vertical {
  position: absolute;
  pointer-events: none;
  inset: 0;
  animation: 4s newModelVerticalAnim 1s both;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -15px;
    background: var(--gradient);
    height: 40px;
    filter: blur(15px);
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    background: var(--gradient);
    height: 40px;
    filter: blur(15px);
  }
}
.new-model-gradient-horizontal {
  position: absolute;
  pointer-events: none;
  inset: 0;
  animation: 4s newModelHorizontalAnim 1s both;
  &:before {
    content: '';
    position: absolute;
    left: -15px;
    bottom: 0;
    top: 0;
    background: var(--tint-color);
    width: 40px;
    filter: blur(15px);
  }
  &:after {
    content: '';
    position: absolute;
    right: -15px;
    bottom: 0;
    top: 0;
    background: var(--secondary-color);
    width: 40px;
    filter: blur(15px);
  }
}
.new-model-container:has(.new-model-item) {
  animation: 1s animVisible both;
}

@keyframes newModelVerticalAnim {
  0% {
    transform: scaleY(1.1);
  }
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.1);
  }
  75% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(1.1);
  }
}
@keyframes newModelHorizontalAnim {
  0% {
    transform: scaleX(1.1);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.1);
  }
  75% {
    opacity: 1;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(1.1);
  }
}