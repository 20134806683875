@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .modal-with_img-content {
    padding: 32px;
  }
  .modal-container {
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .modal-with_img {
    padding: 0;
    margin: 0 auto;
    width: calc(100% - 32px) !important;
  }
  .modal-footer {
    gap: 16px;
  }
  .bottom-modal_media {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .react-responsive-modal-modal {
      width: 100%;
      justify-content: center;
      display: flex;
    }
    .modal-container {
      margin: 0;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      padding: 24px 16px 16px;
      border-radius: 32px 32px 0 0;
      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        height: 4px;
        width: 40px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.1);
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .share-chat-modal-board-head-container {
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    padding: 24px 24px 8px 16px;
  }
  .share-chat-modal-board-head {
    background-color: transparent;
    padding: 0;
    .btn {
      display: none;
    }
  }
  .share-chat-modal-close {
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-color: transparent;
    margin-top: 2px;
  }
  .share-chat-modal-confirm {
    //position: fixed;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 32px 32px 0 0;
    backdrop-filter: blur(40px);
    padding-left: 16px;
    padding-right: 16px;
    p {
      padding: 0 8px;
    }
  }
  .share-chat-modal-confirm-close {
    display: none;
  }
  .share-chat-modal-footer {
    max-width: 100%;
    width: 340px;
    background: var(--gradient);
    margin: 0 auto;
    height: 56px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    margin-bottom: 32px;
      position: relative;
    .text-tint {
      background:none;
      -webkit-text-fill-color: #fff!important;
    }
    .react-icon {
      position: absolute;
      left: 16px;
      path {
        fill: #fff;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .modal-with_img-image {
    display: none;
  }
  .modal-with_img-content {
    width: 100%;
    border: none;
  }
  .generate-image-modal_models-tabs {
    .tabs {
      width: auto;
      white-space: nowrap;
      margin-right: 8px;
    }

    .horizontal-scroll {
      position: absolute;
    }
  }
}

@media only screen and (max-width: 575px) {
  .modal-gallery {
    width: 100vw;
    height: 100%;
    max-height: 100%;
    .slick-slider {
      width: 100vw;
    }
    img {
      width: auto;
      height: auto;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
    .slick-list {
      border-radius: 0;
    }
  }
  .share-chat-modal-confirm-footer {
    flex-direction: column-reverse;
  }
  .sub-manage-about {
    flex-direction: column;
    gap: 8px;
    align-items: center;
    .flex-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .flex-1:nth-child(2) {
      padding: 8px 0;
      border: none;
      border-top: 1px solid #262626;
      border-bottom: 1px solid #262626;
    }
  }
}
@media only screen and (max-width: 374px) {
  .modal-loyalty-system {
    .loyalty-system-status-item-name {
      width: 50px;
    }
  }
}

/* touchscreens */
@media (hover: none) or (max-width: 991px) {

  .bottom-modal {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .react-responsive-modal-modal {
      width: 100%;
      justify-content: center;
      display: flex;
    }

    .modal-container {
      margin: 0;
      width: 100%;
      max-width: 700px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
      padding: 24px 16px 16px;
      border-radius: 32px 32px 0 0;

      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        height: 4px;
        width: 40px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.1);
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

  }

}

/* mouse */
@media (hover: hover) {
  /* ... */
}