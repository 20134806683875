@font-face {/*400*/
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype'),
  url('../fonts/Montserrat-Regular.svg#webfont') format('svg');
}

@font-face {/*700*/
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat-Bold.eot');
  src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Bold.woff') format('woff'),
  url('../fonts/Montserrat-Bold.ttf') format('truetype'),
  url('../fonts/Montserrat-Bold.svg#webfont') format('svg');
}

@font-face {/*900*/
  font-family: 'Montserrat-Black';
  src: url('../fonts/Montserrat-Black.eot');
  src: url('../fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Black.woff') format('woff'),
  url('../fonts/Montserrat-Black.ttf') format('truetype'),
  url('../fonts/Montserrat-Black.svg#webfont') format('svg');
}
/* -------------------------- */


@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../fonts/OpenSans-SemiBold.eot');
  src: local('Open Sans Semi Bold'), local('OpenSans-SemiBold'),
  url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-SemiBold.woff') format('woff'),
  url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../fonts/OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

