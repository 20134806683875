@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none)  {
  .model-card-chat-btn {
    display: none;
  }
  .model-card-about {
    transform: none;
    padding: 16px;
  }
  .model-card-tg:before {
    opacity: 1;
    padding: 24px;
  }
  .model-card:has(.model-card-chat-btn-mobile) .model-card-tg {
    top: 64px;
  }
  .model-card-chat-btn-mobile {
    display: flex;
    position: absolute;
    right: 12px;
    top: 12px;
  }

  .model-card-bg {
    height: 150px!important;
  }
}

/* mouse */
@media (hover: hover) {
  /* ... */
}