.create-image-page {
  padding: 0 24px 24px;
}
.generate-model-btns {
  display: flex;
  gap: 24px;
}
.generate-model-btns.error .btn:first-child {
  background: rgba(227, 63, 63, 0.16) !important;
}

.generate-prompt-card {
  background: #3B3B3B;
  border-radius: 24px;
  margin-top: 8px;
  position: relative;
  border: 1px solid transparent;
  transition: var(--transition);
  &.error {
    border-color: var(--error);
  }
  .form-control, .form-control-wrap {
    border-radius: 24px 24px 0 0;
  }
  .form-control-bg {
    display: none;
  }
  .horizontal-scroll-container {
    width: auto;
    height: 44px;
    flex: 1;
    overflow: hidden;
    margin-right: 8px;
  }

  .horizontal-scroll {
    gap: 8px;
    padding-bottom: 8px;
    bottom: -4px;
  }

  .btn-circle svg {
    width: 20px !important;
    height: 20px !important;
  }

  .horizontal-scroll-secondary {
    &:before {
      background: linear-gradient(to right, #3B3B3B 20%, rgba(18, 18, 18, 0) 100%);
    }

    &:after {
      background: linear-gradient(270deg, #3B3B3B 20%, rgba(18, 18, 18, 0) 100%);
    }
  }

  .horizontal-scroll-arrow {
    //display: none;
    width: 32px !important;
    height: 32px;
    min-width: 32px !important;
    min-height: 32px;
    margin-bottom: 4px;
  }
}
.generate-prompt-tabs {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 60px;
  display: flex;
  padding: 2px;
  height: 40px;
}

.generate-prompt-tab {
  flex: 1;
  transition: var(--transition);
  cursor: pointer;
  font-family: var(--font-bold);
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.64);
  border-radius: 60px;
  display: grid;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff;
  }

  &.active {
    background-color: #fff;
    color: var(--bg-main);
  }
}

.generate-prompt-tabs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px;
  padding-top: 8px;
  max-height: 164px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    margin-bottom: 12px;
    margin-top: 4px;
  }

  .btn, .btn-content {
    border-radius: 60px;
  }

}

.generate-image-count-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: 124px;
  .horizontal-scroll {
    padding: 2px;
    gap: 8px;
    padding-bottom: 12px;
  }
  .border-gradient-secondary {
    cursor: pointer;
    width: 157px;
    min-width: 157px;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px;
    border: 1px solid var(--border-color);
    border-radius: 24px;

    &:hover:before, &.active:before {
      opacity: 1;
    }

    &:before {
      left: -2px;
      bottom: -2px;
      top: -2px;
      right: -2px;
      opacity: 0;
      border-radius: 26px;
    }

    &:after {
      border-radius: 24px;
    }
  }

  .credit-plan-coins {
    margin: 0;
    padding: 0;
    z-index: 1;
    margin-top: 4px;
  }

  .form-radio {
    z-index: 2;
  }
}

.generate-image-count-badge {
  height: 24px;
  padding-left: 8px;
  border-radius: 24px 8px 8px 24px;
  padding-right: 24px;
  position: relative;
  z-index: 2;
  background: var(--gradient);
  display: flex;
  align-items: center;
  margin-right: -16px;
  font-size: 10px;
  line-height: 20px;
  font-family: var(--font-bold);

  &.free {
    background: rgba(18, 18, 18, 0.2);
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.generate-image-filter-list {
  display: flex;
  flex-wrap: wrap;
  height: 156px;

  .horizontal-scroll {
    padding: 2px;
    gap: 8px;
    padding-bottom: 12px;
  }

  .active-element-container {
    width: 120px;
    min-width: 120px;
    height: 100%;
  }

  .active-element:before {
    display: none;
  }

  .model-card {
    aspect-ratio: auto;
    height: 100%;
    width: auto;
  }

  .model-card-category {
    bottom: 12px;
    left: 12px;
    right: 12px;
    top: auto;
  }
}

.generate-image-filter-list .form-radio-custom {
  z-index: 3;
}

.generate-image-filter-list .generate-image-filter-badge {
  position: absolute;
  z-index: 1;
  background: var(--gradient);
  height: 24px;
  padding-left: 8px;
  border-radius: 24px;
  padding-right: 28px;
  background: var(--gradient);
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 20px;
  font-family: var(--font-bold);
  right: 16px;
  top: 12px;

  svg {
    width: 16px;
    height: 16px;
  }
}