.create-ai-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.create-ai-card {
  padding: 24px;
  width: 700px;
  border-radius: 32px;
}

.create-ai-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 24px;

  .react-icon {
    cursor: pointer;

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}

.create-ai-arrow {
  width: 24px;
  min-width: 24px;
}

.create-ai-step-footer {
  display: flex;
  margin: 40px -12px 0;

  .btn {
    margin: 0 12px;
  }
}

.create-ai-style {
  display: flex;
  flex-wrap: wrap;
  margin: -12px;

  .active-element-container {
    width: 50%;
    padding: 12px;
  }

  .model-card {
    aspect-ratio: 1;
  }

  .form-radio-custom {
    right: 20px;
    top: 20px;
  }
}

.create-ai-subtitle {
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
}

.create-ai-list {
  margin: -8px;
  display: flex;
  flex-wrap: wrap;

  .active-element-container {
    padding: 8px;
    width: 25%;

  }

  .model-card {
    aspect-ratio: 1/1.34;
  }

  .model-card-category {
    top: auto;
    left: 12px;
    right: 12px;
    bottom: 12px;
  }
}

.create-ai-eye-color {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  justify-content: center;
  padding: 0 56px;

  .btn-content {
    padding-left: 4px !important;
  }

  .btn-image {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 80px;
  }
}

.create-ai-personality {
  margin: -12px;

  .active-element-container {
    width: 33%;
    min-width: 33%;
    padding: 12px;
  }
}

.create-ai-border-item {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.create-ai-personality-item-icon {
  min-height: 36px;
  font-size: 24px;
  display: flex;
  align-items: center;
}


.active-element-content:hover .btn-edit {
  opacity: 1;
  display: flex !important;
}


.create-ai-summary-item-title {
  //line-height: 48px;
  font-family: var(--font-bold);
  font-size: 16px;
  word-break: break-word;
  text-align: center;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-ai-summary-eye {
  width: 48px;
  height: 48px;
  align-self: center;
  border-radius: 50%;
}


.create-ai-summary-item {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  width: 20%;

  .active-element-content {
    aspect-ratio: 1/ 1.34;
  }

  .active-element-container {
    width: 100%;
    margin: 0;

    &.button {
      height: 44px;
      font-size: 14px;

      .active-element-content {
        padding: 0 6px 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

      }

      .btn-edit {
        top: auto;
        right: auto;
        position: relative;
        display: none;
      }
    }
  }

  .model-card-category {
    position: absolute;
    left: 12px;
    bottom: 12px;
    right: 12px;
    top: auto;
    padding: 0 12px;
  }

  .active-element-content.bordered {
    background: rgba(255, 255, 255, 0.1);
    padding: 32px 12px 12px;

    &:hover {
      background-color: #3B3B3B;
    }

    &:after {
      display: none;
    }
  }
}

.create-ai-summary-icon {
  font-size: 48px;
  line-height: 48px;
  text-align: center;
}

.create-ai-title-step {
  display: none;
  align-items: center;

  .react-icon svg {
    width: 24px;
    height: 24px;
  }
}

.create-ai-inner-card {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  padding: 24px;
  margin-top: 24px;
}

.create-ai-personality-item-text {
  margin-top: 3px;
}

.create-ai-list.step-8 .active-element {
  aspect-ratio: 1/0.9;
}

.create-ai-voice {
  .btn-content {
    padding-left: 4px !important;
  }


  .btn.active {
    .create-ai-sound {
      background: var(--gradient);
    }

    .play-btn-container {
      background: var(--gradient);
    }

    .play-btn {
      background-color: transparent;
    }
  }

  .btn:hover, .btn.playing {
    .play-btn-container svg {
      display: block;
    }

    .play-btn {
      background-image: none;
    }
  }

  .play-btn-container {
    border-radius: 50%;
    margin-right: 12px;

    .play-btn {
      background-image: url("../../../../assets/icons/sound.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    svg {
      display: none;
    }
  }
}

.create-ai-sound {
  background: rgba(255, 255, 255, 0.16);
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.create-ai-summary-item.create-ai-voice {
  .btn {
    margin: 0 auto;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 0;
  }
  .play-btn-container {
    margin: 0;
  }
  .play-btn {
    background-color: transparent;
  }
}